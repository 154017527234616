import React, { useContext } from "react";
import { ActivityIndicator } from "react-native";
import { StoreContext } from "../stores/Store";
import Page from "../components/Page";
import colors from "../lib/colors";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Toast from "../components/Toast";
import Router from "./Router";
import { Helmet } from "react-helmet";

interface Props {}

export default function Main(props: Props) {
  const store = useContext(StoreContext);

  if (!store.ready) {
    return (
      <Page
        style={{
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 30
        }}
      >
        <ActivityIndicator color={colors.blue} size="large" />
      </Page>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{store.title}</title>
      </Helmet>
      <Header />
      <Menu />
      <Router {...props} />
      <Toast />
    </React.Fragment>
  );
}

// const styles = StyleSheet.create({
//   backdrop: {
//     position: "absolute",
//     left: 0,
//     top: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: "rgba(0,0,0,.6)",
//     zIndex: 1
//   }
// });
