import React from "react";
import { Text, StyleSheet, TextProps, TextStyle } from "react-native";
import fonts from "../lib/fonts";
import colors from "../lib/colors";

interface Props extends TextProps {
  children?: any;
  style?: TextStyle | any;
}

export default function Sans(props: Props) {
  return (
    <Text {...props} style={[styles.text, props.style]}>
      {props.children}
    </Text>
  );
}

const styles = StyleSheet.create({
  text: {
    fontFamily: fonts.sans,
    color: colors.black,
    lineHeight: 20
  }
});
