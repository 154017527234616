import gql from "graphql-tag";
import { Request } from "../../server/generated/prisma";

export const RequestPayload = gql`
  fragment RequestPayload on Request {
    id
    account {
      id
      mask
      name
      institution
    }
    amount
    canceledAt
    completedAt
    createdAt
    createdBy {
      id
    }
    declinedAt
    for
    from
    fromPaysFee
    fromUser {
      id
      businessName
      email
      firstName
      lastName
      profilePicture
    }
    processedAt
    requestedAt
    send {
      id
      createdAt
      paymentDate
      processedAt
      status
    }
    status
    updatedAt
    user {
      id
      businessName
      email
      firstName
      lastName
      profilePicture
    }
  }
`;

export const CreateRequestMutation = gql`
  mutation createRequest($data: RequestCreateInput!) {
    createRequest(data: $data) {
      ...RequestPayload
    }
  }
  ${RequestPayload}
`;

export const UpdateRequestMutation = gql`
  mutation updateRequest(
    $data: RequestUpdateInput!
    $where: RequestWhereUniqueInput!
  ) {
    updateRequest(data: $data, where: $where) {
      ...RequestPayload
    }
  }
  ${RequestPayload}
`;

export const SendRequestMutation = gql`
  mutation sendRequest($where: RequestWhereUniqueInput!) {
    sendRequest(where: $where) {
      id
    }
  }
`;

export const RequestQuery = gql`
  query request($where: RequestWhereUniqueInput!) {
    request(where: $where) {
      ...RequestPayload
    }
  }
  ${RequestPayload}
`;

export function isRequest(obj): obj is Request {
  return !!obj.from;
}

export const DeleteRequestMutation = gql`
  mutation deleteRequest($where: RequestWhereUniqueInput!) {
    deleteRequest(where: $where) {
      id
    }
  }
`;
