import gql from "graphql-tag";

export const AccountPayload = gql`
  fragment AccountPayload on Account {
    id
    accountId
    institution
    mask
    name
    type
  }
`;

export const DeleteAccountMutation = gql`
  mutation deleteAccount($where: AccountWhereUniqueInput!) {
    deleteAccount(where: $where) {
      id
    }
  }
`;
