import ApolloClient from "apollo-boost";
import Storage from "./Storage";
import fetch from "unfetch";

export const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
  fetch,
  request: async operation => {
    const token = await Storage.getSessionItem("token");
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : null
      }
    });
  }
});
