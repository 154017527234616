import React, { ReactNode } from "react";
import { View, StyleSheet, StyleProp } from "react-native";

interface Props {
  alignCenter?: boolean;
  justifyCenter?: boolean;
  style?: StyleProp<any>;
  children: ReactNode;
}

export default function Row(props: Props) {
  return (
    <View
      style={[
        styles.container,
        props.alignCenter && { alignItems: "center" },
        props.justifyCenter && { justifyContent: "center" },
        props.style
      ]}
    >
      {props.children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row"
  }
});
