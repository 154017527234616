// $gray-100: #F9FBFD !default;
// $gray-200: #F1F4F8 !default;
// $gray-300: #D9E2EF !default;
// $gray-400: #C6D3E6 !default;
// $gray-500: #ABBCD5 !default;
// $gray-600: #869AB8 !default;
// $gray-700: #506690 !default;
// $gray-800: #384C74 !default;
// $gray-900: #2B354F !default;

export default {
  black: "#161C2D",
  blue: "#335EEA",
  blueDark: "#1746e0",
  blueDarker: "#1643d4",
  blueDarkest: "#384C74",
  blueLight: "#6C8AEC",
  blueLighter: "#EAEEFC",
  blueLightest: "#F9FBFD",
  gray: "#384C74",
  grayLight: "#506690",
  grayLighter: "#869AB8",
  grayLightest: "#E9ECF1",
  green: "#42BA96",
  greenLightest: "#E5F8F5",
  lightBlue: "#35A7FF",
  orange: "#FA4616",
  purple: "#7C69EF",
  red: "#DF4759",
  redLightest: "#F5E3E8",
  yellow: "#DAAF38",
  yellowLighter: "#FFF5DA",
};
