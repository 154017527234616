import React, { useContext } from "react";
import { StoreContext } from "../stores/Store";
import { View, StyleSheet } from "react-native";
import Sans from "./Sans";
import Strong from "./Strong";

export default function BusinessInfo(props) {
  const store = useContext(StoreContext);

  let businessName = "";
  let businessMeta = "";

  if (!store.business) {
    businessName = `${store.user.firstName} ${store.user.lastName}`;
    businessMeta = store.user.email;

    if (!store.user.firstName) {
      businessName = store.user.email;
    }

    if (store.user.businessName) {
      businessMeta = businessName;
      businessName = store.user.businessName;
    }
  } else if (store.business) {
    businessName = store.business.businessName;

    if (!businessName) {
      businessName = `${store.business.firstName} ${store.business.lastName}`;
    }

    businessMeta = `${store.user.firstName} ${store.user.lastName}`;

    if (!store.user.firstName) {
      businessMeta = store.user.email;
    }
  }

  return (
    <View style={[styles.container, props.style]}>
      <Sans style={styles.text}>
        <Strong>{businessName}</Strong>
      </Sans>
      <Sans style={[styles.text, styles.meta]}>{businessMeta}</Sans>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginRight: 10,
    flex: 1
  },
  text: {
    lineHeight: 16,
    fontSize: 14,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "right"
  },
  meta: {
    fontSize: 13
  }
});
