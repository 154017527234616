/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAccount
// ====================================================

export interface deleteAccount_deleteAccount {
  __typename: "Account";
  id: string;
}

export interface deleteAccount {
  deleteAccount: deleteAccount_deleteAccount | null;
}

export interface deleteAccountVariables {
  where: AccountWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRequest
// ====================================================

export interface createRequest_createRequest_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface createRequest_createRequest_createdBy {
  __typename: "User";
  id: string;
}

export interface createRequest_createRequest_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface createRequest_createRequest_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface createRequest_createRequest_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface createRequest_createRequest {
  __typename: "Request";
  id: string;
  account: createRequest_createRequest_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: createRequest_createRequest_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: createRequest_createRequest_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: createRequest_createRequest_send | null;
  status: StatusType;
  updatedAt: any;
  user: createRequest_createRequest_user | null;
}

export interface createRequest {
  createRequest: createRequest_createRequest;
}

export interface createRequestVariables {
  data: RequestCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRequest
// ====================================================

export interface updateRequest_updateRequest_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface updateRequest_updateRequest_createdBy {
  __typename: "User";
  id: string;
}

export interface updateRequest_updateRequest_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateRequest_updateRequest_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface updateRequest_updateRequest_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateRequest_updateRequest {
  __typename: "Request";
  id: string;
  account: updateRequest_updateRequest_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updateRequest_updateRequest_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: updateRequest_updateRequest_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: updateRequest_updateRequest_send | null;
  status: StatusType;
  updatedAt: any;
  user: updateRequest_updateRequest_user | null;
}

export interface updateRequest {
  updateRequest: updateRequest_updateRequest | null;
}

export interface updateRequestVariables {
  data: RequestUpdateInput;
  where: RequestWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendRequest
// ====================================================

export interface sendRequest_sendRequest {
  __typename: "Request";
  id: string;
}

export interface sendRequest {
  sendRequest: sendRequest_sendRequest;
}

export interface sendRequestVariables {
  where: RequestWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: request
// ====================================================

export interface request_request_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface request_request_createdBy {
  __typename: "User";
  id: string;
}

export interface request_request_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface request_request_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface request_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface request_request {
  __typename: "Request";
  id: string;
  account: request_request_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: request_request_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: request_request_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: request_request_send | null;
  status: StatusType;
  updatedAt: any;
  user: request_request_user | null;
}

export interface request {
  request: request_request | null;
}

export interface requestVariables {
  where: RequestWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteRequest
// ====================================================

export interface deleteRequest_deleteRequest {
  __typename: "Request";
  id: string;
}

export interface deleteRequest {
  deleteRequest: deleteRequest_deleteRequest | null;
}

export interface deleteRequestVariables {
  where: RequestWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSend
// ====================================================

export interface createSend_createSend_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface createSend_createSend_createdBy {
  __typename: "User";
  id: string;
}

export interface createSend_createSend_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface createSend_createSend_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface createSend_createSend_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: createSend_createSend_request_user | null;
}

export interface createSend_createSend_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface createSend_createSend {
  __typename: "Send";
  id: string;
  account: createSend_createSend_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: createSend_createSend_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: createSend_createSend_toUser | null;
  request: createSend_createSend_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: createSend_createSend_user | null;
}

export interface createSend {
  createSend: createSend_createSend;
}

export interface createSendVariables {
  data: SendCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSend
// ====================================================

export interface updateSend_updateSend_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface updateSend_updateSend_createdBy {
  __typename: "User";
  id: string;
}

export interface updateSend_updateSend_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updateSend_updateSend_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updateSend_updateSend_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: updateSend_updateSend_request_user | null;
}

export interface updateSend_updateSend_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updateSend_updateSend {
  __typename: "Send";
  id: string;
  account: updateSend_updateSend_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updateSend_updateSend_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: updateSend_updateSend_toUser | null;
  request: updateSend_updateSend_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: updateSend_updateSend_user | null;
}

export interface updateSend {
  updateSend: updateSend_updateSend | null;
}

export interface updateSendVariables {
  data: SendUpdateInput;
  where: SendWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: send
// ====================================================

export interface send_send_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface send_send_createdBy {
  __typename: "User";
  id: string;
}

export interface send_send_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface send_send_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface send_send_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: send_send_request_user | null;
}

export interface send_send_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface send_send {
  __typename: "Send";
  id: string;
  account: send_send_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: send_send_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: send_send_toUser | null;
  request: send_send_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: send_send_user | null;
}

export interface send {
  send: send_send | null;
}

export interface sendVariables {
  where: SendWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptPayment
// ====================================================

export interface acceptPayment_acceptPayment_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface acceptPayment_acceptPayment_createdBy {
  __typename: "User";
  id: string;
}

export interface acceptPayment_acceptPayment_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface acceptPayment_acceptPayment_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface acceptPayment_acceptPayment_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: acceptPayment_acceptPayment_request_user | null;
}

export interface acceptPayment_acceptPayment_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface acceptPayment_acceptPayment {
  __typename: "Send";
  id: string;
  account: acceptPayment_acceptPayment_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: acceptPayment_acceptPayment_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: acceptPayment_acceptPayment_toUser | null;
  request: acceptPayment_acceptPayment_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: acceptPayment_acceptPayment_user | null;
}

export interface acceptPayment {
  acceptPayment: acceptPayment_acceptPayment;
}

export interface acceptPaymentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendPayment
// ====================================================

export interface sendPayment_sendPayment {
  __typename: "Send";
  id: string;
}

export interface sendPayment {
  sendPayment: sendPayment_sendPayment;
}

export interface sendPaymentVariables {
  where: SendWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSend
// ====================================================

export interface deleteSend_deleteSend {
  __typename: "Send";
  id: string;
}

export interface deleteSend {
  deleteSend: deleteSend_deleteSend | null;
}

export interface deleteSendVariables {
  where: SendWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyCustomer
// ====================================================

export interface verifyCustomer_verifyCustomer_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface verifyCustomer_verifyCustomer_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface verifyCustomer_verifyCustomer_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface verifyCustomer_verifyCustomer_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyCustomer_verifyCustomer_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface verifyCustomer_verifyCustomer_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_business_froms {
  __typename: "Request";
  id: string;
  account: verifyCustomer_verifyCustomer_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyCustomer_verifyCustomer_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: verifyCustomer_verifyCustomer_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: verifyCustomer_verifyCustomer_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: verifyCustomer_verifyCustomer_business_froms_user | null;
}

export interface verifyCustomer_verifyCustomer_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface verifyCustomer_verifyCustomer_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyCustomer_verifyCustomer_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: verifyCustomer_verifyCustomer_business_members_user | null;
}

export interface verifyCustomer_verifyCustomer_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface verifyCustomer_verifyCustomer_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: verifyCustomer_verifyCustomer_business_pendingInvites_user | null;
}

export interface verifyCustomer_verifyCustomer_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface verifyCustomer_verifyCustomer_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: verifyCustomer_verifyCustomer_business_permissions_business | null;
}

export interface verifyCustomer_verifyCustomer_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface verifyCustomer_verifyCustomer_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyCustomer_verifyCustomer_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface verifyCustomer_verifyCustomer_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_business_requests {
  __typename: "Request";
  id: string;
  account: verifyCustomer_verifyCustomer_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyCustomer_verifyCustomer_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: verifyCustomer_verifyCustomer_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: verifyCustomer_verifyCustomer_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: verifyCustomer_verifyCustomer_business_requests_user | null;
}

export interface verifyCustomer_verifyCustomer_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface verifyCustomer_verifyCustomer_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface verifyCustomer_verifyCustomer_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyCustomer_verifyCustomer_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyCustomer_verifyCustomer_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: verifyCustomer_verifyCustomer_business_sends_request_user | null;
}

export interface verifyCustomer_verifyCustomer_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_business_sends {
  __typename: "Send";
  id: string;
  account: verifyCustomer_verifyCustomer_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyCustomer_verifyCustomer_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: verifyCustomer_verifyCustomer_business_sends_toUser | null;
  request: verifyCustomer_verifyCustomer_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: verifyCustomer_verifyCustomer_business_sends_user | null;
}

export interface verifyCustomer_verifyCustomer_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface verifyCustomer_verifyCustomer_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyCustomer_verifyCustomer_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyCustomer_verifyCustomer_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: verifyCustomer_verifyCustomer_business_tos_request_user | null;
}

export interface verifyCustomer_verifyCustomer_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_business_tos {
  __typename: "Send";
  id: string;
  account: verifyCustomer_verifyCustomer_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyCustomer_verifyCustomer_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: verifyCustomer_verifyCustomer_business_tos_toUser | null;
  request: verifyCustomer_verifyCustomer_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: verifyCustomer_verifyCustomer_business_tos_user | null;
}

export interface verifyCustomer_verifyCustomer_business {
  __typename: "User";
  id: string;
  accounts: verifyCustomer_verifyCustomer_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: verifyCustomer_verifyCustomer_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: verifyCustomer_verifyCustomer_business_froms[] | null;
  invites: verifyCustomer_verifyCustomer_business_invites[];
  lastName: string | null;
  members: verifyCustomer_verifyCustomer_business_members[];
  pendingInvites: verifyCustomer_verifyCustomer_business_pendingInvites[];
  permissions: verifyCustomer_verifyCustomer_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: verifyCustomer_verifyCustomer_business_requests[] | null;
  selectedAccount: verifyCustomer_verifyCustomer_business_selectedAccount | null;
  sends: verifyCustomer_verifyCustomer_business_sends[] | null;
  tos: verifyCustomer_verifyCustomer_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface verifyCustomer_verifyCustomer_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface verifyCustomer_verifyCustomer_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyCustomer_verifyCustomer_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface verifyCustomer_verifyCustomer_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_froms {
  __typename: "Request";
  id: string;
  account: verifyCustomer_verifyCustomer_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyCustomer_verifyCustomer_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: verifyCustomer_verifyCustomer_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: verifyCustomer_verifyCustomer_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: verifyCustomer_verifyCustomer_froms_user | null;
}

export interface verifyCustomer_verifyCustomer_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface verifyCustomer_verifyCustomer_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyCustomer_verifyCustomer_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: verifyCustomer_verifyCustomer_members_user | null;
}

export interface verifyCustomer_verifyCustomer_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface verifyCustomer_verifyCustomer_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: verifyCustomer_verifyCustomer_pendingInvites_user | null;
}

export interface verifyCustomer_verifyCustomer_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface verifyCustomer_verifyCustomer_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: verifyCustomer_verifyCustomer_permissions_business | null;
}

export interface verifyCustomer_verifyCustomer_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface verifyCustomer_verifyCustomer_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyCustomer_verifyCustomer_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface verifyCustomer_verifyCustomer_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_requests {
  __typename: "Request";
  id: string;
  account: verifyCustomer_verifyCustomer_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyCustomer_verifyCustomer_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: verifyCustomer_verifyCustomer_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: verifyCustomer_verifyCustomer_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: verifyCustomer_verifyCustomer_requests_user | null;
}

export interface verifyCustomer_verifyCustomer_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface verifyCustomer_verifyCustomer_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface verifyCustomer_verifyCustomer_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyCustomer_verifyCustomer_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyCustomer_verifyCustomer_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: verifyCustomer_verifyCustomer_sends_request_user | null;
}

export interface verifyCustomer_verifyCustomer_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_sends {
  __typename: "Send";
  id: string;
  account: verifyCustomer_verifyCustomer_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyCustomer_verifyCustomer_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: verifyCustomer_verifyCustomer_sends_toUser | null;
  request: verifyCustomer_verifyCustomer_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: verifyCustomer_verifyCustomer_sends_user | null;
}

export interface verifyCustomer_verifyCustomer_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface verifyCustomer_verifyCustomer_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyCustomer_verifyCustomer_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyCustomer_verifyCustomer_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: verifyCustomer_verifyCustomer_tos_request_user | null;
}

export interface verifyCustomer_verifyCustomer_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyCustomer_verifyCustomer_tos {
  __typename: "Send";
  id: string;
  account: verifyCustomer_verifyCustomer_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyCustomer_verifyCustomer_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: verifyCustomer_verifyCustomer_tos_toUser | null;
  request: verifyCustomer_verifyCustomer_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: verifyCustomer_verifyCustomer_tos_user | null;
}

export interface verifyCustomer_verifyCustomer {
  __typename: "User";
  id: string;
  accounts: verifyCustomer_verifyCustomer_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: verifyCustomer_verifyCustomer_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: verifyCustomer_verifyCustomer_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: verifyCustomer_verifyCustomer_froms[] | null;
  invites: verifyCustomer_verifyCustomer_invites[];
  lastName: string | null;
  members: verifyCustomer_verifyCustomer_members[];
  pendingInvites: verifyCustomer_verifyCustomer_pendingInvites[];
  permissions: verifyCustomer_verifyCustomer_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: verifyCustomer_verifyCustomer_requests[] | null;
  selectedAccount: verifyCustomer_verifyCustomer_selectedAccount | null;
  sends: verifyCustomer_verifyCustomer_sends[] | null;
  timezone: string | null;
  tos: verifyCustomer_verifyCustomer_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface verifyCustomer {
  verifyCustomer: verifyCustomer_verifyCustomer;
}

export interface verifyCustomerVariables {
  data: VerifyCustomerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyBeneficialOwner
// ====================================================

export interface verifyBeneficialOwner_verifyBeneficialOwner_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_froms {
  __typename: "Request";
  id: string;
  account: verifyBeneficialOwner_verifyBeneficialOwner_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyBeneficialOwner_verifyBeneficialOwner_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: verifyBeneficialOwner_verifyBeneficialOwner_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: verifyBeneficialOwner_verifyBeneficialOwner_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: verifyBeneficialOwner_verifyBeneficialOwner_business_froms_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: verifyBeneficialOwner_verifyBeneficialOwner_business_members_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: verifyBeneficialOwner_verifyBeneficialOwner_business_pendingInvites_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: verifyBeneficialOwner_verifyBeneficialOwner_business_permissions_business | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_requests {
  __typename: "Request";
  id: string;
  account: verifyBeneficialOwner_verifyBeneficialOwner_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyBeneficialOwner_verifyBeneficialOwner_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: verifyBeneficialOwner_verifyBeneficialOwner_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: verifyBeneficialOwner_verifyBeneficialOwner_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: verifyBeneficialOwner_verifyBeneficialOwner_business_requests_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: verifyBeneficialOwner_verifyBeneficialOwner_business_sends_request_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_sends {
  __typename: "Send";
  id: string;
  account: verifyBeneficialOwner_verifyBeneficialOwner_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyBeneficialOwner_verifyBeneficialOwner_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: verifyBeneficialOwner_verifyBeneficialOwner_business_sends_toUser | null;
  request: verifyBeneficialOwner_verifyBeneficialOwner_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: verifyBeneficialOwner_verifyBeneficialOwner_business_sends_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: verifyBeneficialOwner_verifyBeneficialOwner_business_tos_request_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business_tos {
  __typename: "Send";
  id: string;
  account: verifyBeneficialOwner_verifyBeneficialOwner_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyBeneficialOwner_verifyBeneficialOwner_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: verifyBeneficialOwner_verifyBeneficialOwner_business_tos_toUser | null;
  request: verifyBeneficialOwner_verifyBeneficialOwner_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: verifyBeneficialOwner_verifyBeneficialOwner_business_tos_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_business {
  __typename: "User";
  id: string;
  accounts: verifyBeneficialOwner_verifyBeneficialOwner_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: verifyBeneficialOwner_verifyBeneficialOwner_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: verifyBeneficialOwner_verifyBeneficialOwner_business_froms[] | null;
  invites: verifyBeneficialOwner_verifyBeneficialOwner_business_invites[];
  lastName: string | null;
  members: verifyBeneficialOwner_verifyBeneficialOwner_business_members[];
  pendingInvites: verifyBeneficialOwner_verifyBeneficialOwner_business_pendingInvites[];
  permissions: verifyBeneficialOwner_verifyBeneficialOwner_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: verifyBeneficialOwner_verifyBeneficialOwner_business_requests[] | null;
  selectedAccount: verifyBeneficialOwner_verifyBeneficialOwner_business_selectedAccount | null;
  sends: verifyBeneficialOwner_verifyBeneficialOwner_business_sends[] | null;
  tos: verifyBeneficialOwner_verifyBeneficialOwner_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_froms {
  __typename: "Request";
  id: string;
  account: verifyBeneficialOwner_verifyBeneficialOwner_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyBeneficialOwner_verifyBeneficialOwner_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: verifyBeneficialOwner_verifyBeneficialOwner_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: verifyBeneficialOwner_verifyBeneficialOwner_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: verifyBeneficialOwner_verifyBeneficialOwner_froms_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: verifyBeneficialOwner_verifyBeneficialOwner_members_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: verifyBeneficialOwner_verifyBeneficialOwner_pendingInvites_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: verifyBeneficialOwner_verifyBeneficialOwner_permissions_business | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_requests {
  __typename: "Request";
  id: string;
  account: verifyBeneficialOwner_verifyBeneficialOwner_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyBeneficialOwner_verifyBeneficialOwner_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: verifyBeneficialOwner_verifyBeneficialOwner_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: verifyBeneficialOwner_verifyBeneficialOwner_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: verifyBeneficialOwner_verifyBeneficialOwner_requests_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: verifyBeneficialOwner_verifyBeneficialOwner_sends_request_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_sends {
  __typename: "Send";
  id: string;
  account: verifyBeneficialOwner_verifyBeneficialOwner_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyBeneficialOwner_verifyBeneficialOwner_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: verifyBeneficialOwner_verifyBeneficialOwner_sends_toUser | null;
  request: verifyBeneficialOwner_verifyBeneficialOwner_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: verifyBeneficialOwner_verifyBeneficialOwner_sends_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: verifyBeneficialOwner_verifyBeneficialOwner_tos_request_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner_tos {
  __typename: "Send";
  id: string;
  account: verifyBeneficialOwner_verifyBeneficialOwner_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: verifyBeneficialOwner_verifyBeneficialOwner_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: verifyBeneficialOwner_verifyBeneficialOwner_tos_toUser | null;
  request: verifyBeneficialOwner_verifyBeneficialOwner_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: verifyBeneficialOwner_verifyBeneficialOwner_tos_user | null;
}

export interface verifyBeneficialOwner_verifyBeneficialOwner {
  __typename: "User";
  id: string;
  accounts: verifyBeneficialOwner_verifyBeneficialOwner_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: verifyBeneficialOwner_verifyBeneficialOwner_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: verifyBeneficialOwner_verifyBeneficialOwner_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: verifyBeneficialOwner_verifyBeneficialOwner_froms[] | null;
  invites: verifyBeneficialOwner_verifyBeneficialOwner_invites[];
  lastName: string | null;
  members: verifyBeneficialOwner_verifyBeneficialOwner_members[];
  pendingInvites: verifyBeneficialOwner_verifyBeneficialOwner_pendingInvites[];
  permissions: verifyBeneficialOwner_verifyBeneficialOwner_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: verifyBeneficialOwner_verifyBeneficialOwner_requests[] | null;
  selectedAccount: verifyBeneficialOwner_verifyBeneficialOwner_selectedAccount | null;
  sends: verifyBeneficialOwner_verifyBeneficialOwner_sends[] | null;
  timezone: string | null;
  tos: verifyBeneficialOwner_verifyBeneficialOwner_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface verifyBeneficialOwner {
  verifyBeneficialOwner: verifyBeneficialOwner_verifyBeneficialOwner;
}

export interface verifyBeneficialOwnerVariables {
  data: VerifyBeneficialOwnerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signup
// ====================================================

export interface signup_signup_user_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface signup_signup_user_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface signup_signup_user_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface signup_signup_user_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface signup_signup_user_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface signup_signup_user_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_business_froms {
  __typename: "Request";
  id: string;
  account: signup_signup_user_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signup_signup_user_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: signup_signup_user_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: signup_signup_user_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: signup_signup_user_business_froms_user | null;
}

export interface signup_signup_user_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface signup_signup_user_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signup_signup_user_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: signup_signup_user_business_members_user | null;
}

export interface signup_signup_user_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface signup_signup_user_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: signup_signup_user_business_pendingInvites_user | null;
}

export interface signup_signup_user_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface signup_signup_user_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: signup_signup_user_business_permissions_business | null;
}

export interface signup_signup_user_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface signup_signup_user_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface signup_signup_user_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface signup_signup_user_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_business_requests {
  __typename: "Request";
  id: string;
  account: signup_signup_user_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signup_signup_user_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: signup_signup_user_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: signup_signup_user_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: signup_signup_user_business_requests_user | null;
}

export interface signup_signup_user_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface signup_signup_user_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface signup_signup_user_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface signup_signup_user_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signup_signup_user_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: signup_signup_user_business_sends_request_user | null;
}

export interface signup_signup_user_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_business_sends {
  __typename: "Send";
  id: string;
  account: signup_signup_user_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signup_signup_user_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: signup_signup_user_business_sends_toUser | null;
  request: signup_signup_user_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: signup_signup_user_business_sends_user | null;
}

export interface signup_signup_user_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface signup_signup_user_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface signup_signup_user_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signup_signup_user_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: signup_signup_user_business_tos_request_user | null;
}

export interface signup_signup_user_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_business_tos {
  __typename: "Send";
  id: string;
  account: signup_signup_user_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signup_signup_user_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: signup_signup_user_business_tos_toUser | null;
  request: signup_signup_user_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: signup_signup_user_business_tos_user | null;
}

export interface signup_signup_user_business {
  __typename: "User";
  id: string;
  accounts: signup_signup_user_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: signup_signup_user_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: signup_signup_user_business_froms[] | null;
  invites: signup_signup_user_business_invites[];
  lastName: string | null;
  members: signup_signup_user_business_members[];
  pendingInvites: signup_signup_user_business_pendingInvites[];
  permissions: signup_signup_user_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: signup_signup_user_business_requests[] | null;
  selectedAccount: signup_signup_user_business_selectedAccount | null;
  sends: signup_signup_user_business_sends[] | null;
  tos: signup_signup_user_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface signup_signup_user_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface signup_signup_user_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface signup_signup_user_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface signup_signup_user_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_froms {
  __typename: "Request";
  id: string;
  account: signup_signup_user_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signup_signup_user_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: signup_signup_user_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: signup_signup_user_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: signup_signup_user_froms_user | null;
}

export interface signup_signup_user_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface signup_signup_user_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signup_signup_user_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: signup_signup_user_members_user | null;
}

export interface signup_signup_user_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface signup_signup_user_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: signup_signup_user_pendingInvites_user | null;
}

export interface signup_signup_user_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface signup_signup_user_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: signup_signup_user_permissions_business | null;
}

export interface signup_signup_user_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface signup_signup_user_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface signup_signup_user_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface signup_signup_user_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_requests {
  __typename: "Request";
  id: string;
  account: signup_signup_user_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signup_signup_user_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: signup_signup_user_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: signup_signup_user_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: signup_signup_user_requests_user | null;
}

export interface signup_signup_user_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface signup_signup_user_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface signup_signup_user_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface signup_signup_user_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signup_signup_user_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: signup_signup_user_sends_request_user | null;
}

export interface signup_signup_user_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_sends {
  __typename: "Send";
  id: string;
  account: signup_signup_user_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signup_signup_user_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: signup_signup_user_sends_toUser | null;
  request: signup_signup_user_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: signup_signup_user_sends_user | null;
}

export interface signup_signup_user_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface signup_signup_user_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface signup_signup_user_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signup_signup_user_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: signup_signup_user_tos_request_user | null;
}

export interface signup_signup_user_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signup_signup_user_tos {
  __typename: "Send";
  id: string;
  account: signup_signup_user_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signup_signup_user_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: signup_signup_user_tos_toUser | null;
  request: signup_signup_user_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: signup_signup_user_tos_user | null;
}

export interface signup_signup_user {
  __typename: "User";
  id: string;
  accounts: signup_signup_user_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: signup_signup_user_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: signup_signup_user_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: signup_signup_user_froms[] | null;
  invites: signup_signup_user_invites[];
  lastName: string | null;
  members: signup_signup_user_members[];
  pendingInvites: signup_signup_user_pendingInvites[];
  permissions: signup_signup_user_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: signup_signup_user_requests[] | null;
  selectedAccount: signup_signup_user_selectedAccount | null;
  sends: signup_signup_user_sends[] | null;
  timezone: string | null;
  tos: signup_signup_user_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface signup_signup {
  __typename: "AuthPayload";
  token: string;
  user: signup_signup_user;
}

export interface signup {
  signup: signup_signup;
}

export interface signupVariables {
  data: SignupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signupAndPay
// ====================================================

export interface signupAndPay_signupAndPay_user_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface signupAndPay_signupAndPay_user_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface signupAndPay_signupAndPay_user_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface signupAndPay_signupAndPay_user_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface signupAndPay_signupAndPay_user_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface signupAndPay_signupAndPay_user_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_business_froms {
  __typename: "Request";
  id: string;
  account: signupAndPay_signupAndPay_user_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signupAndPay_signupAndPay_user_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: signupAndPay_signupAndPay_user_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: signupAndPay_signupAndPay_user_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: signupAndPay_signupAndPay_user_business_froms_user | null;
}

export interface signupAndPay_signupAndPay_user_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface signupAndPay_signupAndPay_user_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signupAndPay_signupAndPay_user_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: signupAndPay_signupAndPay_user_business_members_user | null;
}

export interface signupAndPay_signupAndPay_user_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface signupAndPay_signupAndPay_user_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: signupAndPay_signupAndPay_user_business_pendingInvites_user | null;
}

export interface signupAndPay_signupAndPay_user_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface signupAndPay_signupAndPay_user_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: signupAndPay_signupAndPay_user_business_permissions_business | null;
}

export interface signupAndPay_signupAndPay_user_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface signupAndPay_signupAndPay_user_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface signupAndPay_signupAndPay_user_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface signupAndPay_signupAndPay_user_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_business_requests {
  __typename: "Request";
  id: string;
  account: signupAndPay_signupAndPay_user_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signupAndPay_signupAndPay_user_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: signupAndPay_signupAndPay_user_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: signupAndPay_signupAndPay_user_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: signupAndPay_signupAndPay_user_business_requests_user | null;
}

export interface signupAndPay_signupAndPay_user_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface signupAndPay_signupAndPay_user_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface signupAndPay_signupAndPay_user_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface signupAndPay_signupAndPay_user_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signupAndPay_signupAndPay_user_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: signupAndPay_signupAndPay_user_business_sends_request_user | null;
}

export interface signupAndPay_signupAndPay_user_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_business_sends {
  __typename: "Send";
  id: string;
  account: signupAndPay_signupAndPay_user_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signupAndPay_signupAndPay_user_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: signupAndPay_signupAndPay_user_business_sends_toUser | null;
  request: signupAndPay_signupAndPay_user_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: signupAndPay_signupAndPay_user_business_sends_user | null;
}

export interface signupAndPay_signupAndPay_user_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface signupAndPay_signupAndPay_user_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface signupAndPay_signupAndPay_user_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signupAndPay_signupAndPay_user_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: signupAndPay_signupAndPay_user_business_tos_request_user | null;
}

export interface signupAndPay_signupAndPay_user_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_business_tos {
  __typename: "Send";
  id: string;
  account: signupAndPay_signupAndPay_user_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signupAndPay_signupAndPay_user_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: signupAndPay_signupAndPay_user_business_tos_toUser | null;
  request: signupAndPay_signupAndPay_user_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: signupAndPay_signupAndPay_user_business_tos_user | null;
}

export interface signupAndPay_signupAndPay_user_business {
  __typename: "User";
  id: string;
  accounts: signupAndPay_signupAndPay_user_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: signupAndPay_signupAndPay_user_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: signupAndPay_signupAndPay_user_business_froms[] | null;
  invites: signupAndPay_signupAndPay_user_business_invites[];
  lastName: string | null;
  members: signupAndPay_signupAndPay_user_business_members[];
  pendingInvites: signupAndPay_signupAndPay_user_business_pendingInvites[];
  permissions: signupAndPay_signupAndPay_user_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: signupAndPay_signupAndPay_user_business_requests[] | null;
  selectedAccount: signupAndPay_signupAndPay_user_business_selectedAccount | null;
  sends: signupAndPay_signupAndPay_user_business_sends[] | null;
  tos: signupAndPay_signupAndPay_user_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface signupAndPay_signupAndPay_user_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface signupAndPay_signupAndPay_user_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface signupAndPay_signupAndPay_user_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface signupAndPay_signupAndPay_user_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_froms {
  __typename: "Request";
  id: string;
  account: signupAndPay_signupAndPay_user_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signupAndPay_signupAndPay_user_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: signupAndPay_signupAndPay_user_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: signupAndPay_signupAndPay_user_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: signupAndPay_signupAndPay_user_froms_user | null;
}

export interface signupAndPay_signupAndPay_user_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface signupAndPay_signupAndPay_user_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signupAndPay_signupAndPay_user_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: signupAndPay_signupAndPay_user_members_user | null;
}

export interface signupAndPay_signupAndPay_user_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface signupAndPay_signupAndPay_user_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: signupAndPay_signupAndPay_user_pendingInvites_user | null;
}

export interface signupAndPay_signupAndPay_user_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface signupAndPay_signupAndPay_user_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: signupAndPay_signupAndPay_user_permissions_business | null;
}

export interface signupAndPay_signupAndPay_user_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface signupAndPay_signupAndPay_user_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface signupAndPay_signupAndPay_user_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface signupAndPay_signupAndPay_user_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_requests {
  __typename: "Request";
  id: string;
  account: signupAndPay_signupAndPay_user_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signupAndPay_signupAndPay_user_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: signupAndPay_signupAndPay_user_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: signupAndPay_signupAndPay_user_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: signupAndPay_signupAndPay_user_requests_user | null;
}

export interface signupAndPay_signupAndPay_user_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface signupAndPay_signupAndPay_user_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface signupAndPay_signupAndPay_user_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface signupAndPay_signupAndPay_user_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signupAndPay_signupAndPay_user_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: signupAndPay_signupAndPay_user_sends_request_user | null;
}

export interface signupAndPay_signupAndPay_user_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_sends {
  __typename: "Send";
  id: string;
  account: signupAndPay_signupAndPay_user_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signupAndPay_signupAndPay_user_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: signupAndPay_signupAndPay_user_sends_toUser | null;
  request: signupAndPay_signupAndPay_user_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: signupAndPay_signupAndPay_user_sends_user | null;
}

export interface signupAndPay_signupAndPay_user_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface signupAndPay_signupAndPay_user_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface signupAndPay_signupAndPay_user_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface signupAndPay_signupAndPay_user_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: signupAndPay_signupAndPay_user_tos_request_user | null;
}

export interface signupAndPay_signupAndPay_user_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface signupAndPay_signupAndPay_user_tos {
  __typename: "Send";
  id: string;
  account: signupAndPay_signupAndPay_user_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: signupAndPay_signupAndPay_user_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: signupAndPay_signupAndPay_user_tos_toUser | null;
  request: signupAndPay_signupAndPay_user_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: signupAndPay_signupAndPay_user_tos_user | null;
}

export interface signupAndPay_signupAndPay_user {
  __typename: "User";
  id: string;
  accounts: signupAndPay_signupAndPay_user_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: signupAndPay_signupAndPay_user_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: signupAndPay_signupAndPay_user_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: signupAndPay_signupAndPay_user_froms[] | null;
  invites: signupAndPay_signupAndPay_user_invites[];
  lastName: string | null;
  members: signupAndPay_signupAndPay_user_members[];
  pendingInvites: signupAndPay_signupAndPay_user_pendingInvites[];
  permissions: signupAndPay_signupAndPay_user_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: signupAndPay_signupAndPay_user_requests[] | null;
  selectedAccount: signupAndPay_signupAndPay_user_selectedAccount | null;
  sends: signupAndPay_signupAndPay_user_sends[] | null;
  timezone: string | null;
  tos: signupAndPay_signupAndPay_user_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface signupAndPay_signupAndPay {
  __typename: "AuthPayload";
  token: string;
  user: signupAndPay_signupAndPay_user;
}

export interface signupAndPay {
  signupAndPay: signupAndPay_signupAndPay;
}

export interface signupAndPayVariables {
  data: SignupAndPayInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login_login_user_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface login_login_user_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface login_login_user_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface login_login_user_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface login_login_user_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface login_login_user_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface login_login_user_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface login_login_user_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface login_login_user_business_froms {
  __typename: "Request";
  id: string;
  account: login_login_user_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: login_login_user_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: login_login_user_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: login_login_user_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: login_login_user_business_froms_user | null;
}

export interface login_login_user_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface login_login_user_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface login_login_user_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: login_login_user_business_members_user | null;
}

export interface login_login_user_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface login_login_user_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: login_login_user_business_pendingInvites_user | null;
}

export interface login_login_user_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface login_login_user_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: login_login_user_business_permissions_business | null;
}

export interface login_login_user_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface login_login_user_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface login_login_user_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface login_login_user_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface login_login_user_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface login_login_user_business_requests {
  __typename: "Request";
  id: string;
  account: login_login_user_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: login_login_user_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: login_login_user_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: login_login_user_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: login_login_user_business_requests_user | null;
}

export interface login_login_user_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface login_login_user_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface login_login_user_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface login_login_user_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface login_login_user_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface login_login_user_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: login_login_user_business_sends_request_user | null;
}

export interface login_login_user_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface login_login_user_business_sends {
  __typename: "Send";
  id: string;
  account: login_login_user_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: login_login_user_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: login_login_user_business_sends_toUser | null;
  request: login_login_user_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: login_login_user_business_sends_user | null;
}

export interface login_login_user_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface login_login_user_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface login_login_user_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface login_login_user_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface login_login_user_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: login_login_user_business_tos_request_user | null;
}

export interface login_login_user_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface login_login_user_business_tos {
  __typename: "Send";
  id: string;
  account: login_login_user_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: login_login_user_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: login_login_user_business_tos_toUser | null;
  request: login_login_user_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: login_login_user_business_tos_user | null;
}

export interface login_login_user_business {
  __typename: "User";
  id: string;
  accounts: login_login_user_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: login_login_user_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: login_login_user_business_froms[] | null;
  invites: login_login_user_business_invites[];
  lastName: string | null;
  members: login_login_user_business_members[];
  pendingInvites: login_login_user_business_pendingInvites[];
  permissions: login_login_user_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: login_login_user_business_requests[] | null;
  selectedAccount: login_login_user_business_selectedAccount | null;
  sends: login_login_user_business_sends[] | null;
  tos: login_login_user_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface login_login_user_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface login_login_user_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface login_login_user_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface login_login_user_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface login_login_user_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface login_login_user_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface login_login_user_froms {
  __typename: "Request";
  id: string;
  account: login_login_user_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: login_login_user_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: login_login_user_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: login_login_user_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: login_login_user_froms_user | null;
}

export interface login_login_user_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface login_login_user_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface login_login_user_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: login_login_user_members_user | null;
}

export interface login_login_user_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface login_login_user_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: login_login_user_pendingInvites_user | null;
}

export interface login_login_user_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface login_login_user_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: login_login_user_permissions_business | null;
}

export interface login_login_user_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface login_login_user_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface login_login_user_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface login_login_user_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface login_login_user_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface login_login_user_requests {
  __typename: "Request";
  id: string;
  account: login_login_user_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: login_login_user_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: login_login_user_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: login_login_user_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: login_login_user_requests_user | null;
}

export interface login_login_user_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface login_login_user_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface login_login_user_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface login_login_user_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface login_login_user_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface login_login_user_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: login_login_user_sends_request_user | null;
}

export interface login_login_user_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface login_login_user_sends {
  __typename: "Send";
  id: string;
  account: login_login_user_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: login_login_user_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: login_login_user_sends_toUser | null;
  request: login_login_user_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: login_login_user_sends_user | null;
}

export interface login_login_user_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface login_login_user_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface login_login_user_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface login_login_user_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface login_login_user_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: login_login_user_tos_request_user | null;
}

export interface login_login_user_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface login_login_user_tos {
  __typename: "Send";
  id: string;
  account: login_login_user_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: login_login_user_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: login_login_user_tos_toUser | null;
  request: login_login_user_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: login_login_user_tos_user | null;
}

export interface login_login_user {
  __typename: "User";
  id: string;
  accounts: login_login_user_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: login_login_user_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: login_login_user_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: login_login_user_froms[] | null;
  invites: login_login_user_invites[];
  lastName: string | null;
  members: login_login_user_members[];
  pendingInvites: login_login_user_pendingInvites[];
  permissions: login_login_user_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: login_login_user_requests[] | null;
  selectedAccount: login_login_user_selectedAccount | null;
  sends: login_login_user_sends[] | null;
  timezone: string | null;
  tos: login_login_user_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface login_login {
  __typename: "AuthPayload";
  token: string;
  user: login_login_user;
}

export interface login {
  login: login_login;
}

export interface loginVariables {
  data: LoginInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetPassword
// ====================================================

export interface resetPassword_resetPassword {
  __typename: "SuccessPayload";
  success: boolean;
}

export interface resetPassword {
  resetPassword: resetPassword_resetPassword;
}

export interface resetPasswordVariables {
  data: ResetPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resendEmailValidation
// ====================================================

export interface resendEmailValidation_resendEmailValidation {
  __typename: "SuccessPayload";
  success: boolean;
}

export interface resendEmailValidation {
  resendEmailValidation: resendEmailValidation_resendEmailValidation;
}

export interface resendEmailValidationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePassword
// ====================================================

export interface updatePassword_updatePassword_user_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface updatePassword_updatePassword_user_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface updatePassword_updatePassword_user_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface updatePassword_updatePassword_user_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface updatePassword_updatePassword_user_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface updatePassword_updatePassword_user_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_business_froms {
  __typename: "Request";
  id: string;
  account: updatePassword_updatePassword_user_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updatePassword_updatePassword_user_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: updatePassword_updatePassword_user_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: updatePassword_updatePassword_user_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: updatePassword_updatePassword_user_business_froms_user | null;
}

export interface updatePassword_updatePassword_user_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface updatePassword_updatePassword_user_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updatePassword_updatePassword_user_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: updatePassword_updatePassword_user_business_members_user | null;
}

export interface updatePassword_updatePassword_user_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface updatePassword_updatePassword_user_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: updatePassword_updatePassword_user_business_pendingInvites_user | null;
}

export interface updatePassword_updatePassword_user_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface updatePassword_updatePassword_user_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: updatePassword_updatePassword_user_business_permissions_business | null;
}

export interface updatePassword_updatePassword_user_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface updatePassword_updatePassword_user_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface updatePassword_updatePassword_user_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface updatePassword_updatePassword_user_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_business_requests {
  __typename: "Request";
  id: string;
  account: updatePassword_updatePassword_user_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updatePassword_updatePassword_user_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: updatePassword_updatePassword_user_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: updatePassword_updatePassword_user_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: updatePassword_updatePassword_user_business_requests_user | null;
}

export interface updatePassword_updatePassword_user_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface updatePassword_updatePassword_user_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface updatePassword_updatePassword_user_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface updatePassword_updatePassword_user_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updatePassword_updatePassword_user_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: updatePassword_updatePassword_user_business_sends_request_user | null;
}

export interface updatePassword_updatePassword_user_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_business_sends {
  __typename: "Send";
  id: string;
  account: updatePassword_updatePassword_user_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updatePassword_updatePassword_user_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: updatePassword_updatePassword_user_business_sends_toUser | null;
  request: updatePassword_updatePassword_user_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: updatePassword_updatePassword_user_business_sends_user | null;
}

export interface updatePassword_updatePassword_user_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface updatePassword_updatePassword_user_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface updatePassword_updatePassword_user_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updatePassword_updatePassword_user_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: updatePassword_updatePassword_user_business_tos_request_user | null;
}

export interface updatePassword_updatePassword_user_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_business_tos {
  __typename: "Send";
  id: string;
  account: updatePassword_updatePassword_user_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updatePassword_updatePassword_user_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: updatePassword_updatePassword_user_business_tos_toUser | null;
  request: updatePassword_updatePassword_user_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: updatePassword_updatePassword_user_business_tos_user | null;
}

export interface updatePassword_updatePassword_user_business {
  __typename: "User";
  id: string;
  accounts: updatePassword_updatePassword_user_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: updatePassword_updatePassword_user_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: updatePassword_updatePassword_user_business_froms[] | null;
  invites: updatePassword_updatePassword_user_business_invites[];
  lastName: string | null;
  members: updatePassword_updatePassword_user_business_members[];
  pendingInvites: updatePassword_updatePassword_user_business_pendingInvites[];
  permissions: updatePassword_updatePassword_user_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: updatePassword_updatePassword_user_business_requests[] | null;
  selectedAccount: updatePassword_updatePassword_user_business_selectedAccount | null;
  sends: updatePassword_updatePassword_user_business_sends[] | null;
  tos: updatePassword_updatePassword_user_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface updatePassword_updatePassword_user_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface updatePassword_updatePassword_user_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface updatePassword_updatePassword_user_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface updatePassword_updatePassword_user_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_froms {
  __typename: "Request";
  id: string;
  account: updatePassword_updatePassword_user_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updatePassword_updatePassword_user_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: updatePassword_updatePassword_user_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: updatePassword_updatePassword_user_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: updatePassword_updatePassword_user_froms_user | null;
}

export interface updatePassword_updatePassword_user_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface updatePassword_updatePassword_user_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updatePassword_updatePassword_user_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: updatePassword_updatePassword_user_members_user | null;
}

export interface updatePassword_updatePassword_user_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface updatePassword_updatePassword_user_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: updatePassword_updatePassword_user_pendingInvites_user | null;
}

export interface updatePassword_updatePassword_user_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface updatePassword_updatePassword_user_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: updatePassword_updatePassword_user_permissions_business | null;
}

export interface updatePassword_updatePassword_user_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface updatePassword_updatePassword_user_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface updatePassword_updatePassword_user_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface updatePassword_updatePassword_user_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_requests {
  __typename: "Request";
  id: string;
  account: updatePassword_updatePassword_user_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updatePassword_updatePassword_user_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: updatePassword_updatePassword_user_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: updatePassword_updatePassword_user_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: updatePassword_updatePassword_user_requests_user | null;
}

export interface updatePassword_updatePassword_user_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface updatePassword_updatePassword_user_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface updatePassword_updatePassword_user_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface updatePassword_updatePassword_user_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updatePassword_updatePassword_user_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: updatePassword_updatePassword_user_sends_request_user | null;
}

export interface updatePassword_updatePassword_user_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_sends {
  __typename: "Send";
  id: string;
  account: updatePassword_updatePassword_user_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updatePassword_updatePassword_user_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: updatePassword_updatePassword_user_sends_toUser | null;
  request: updatePassword_updatePassword_user_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: updatePassword_updatePassword_user_sends_user | null;
}

export interface updatePassword_updatePassword_user_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface updatePassword_updatePassword_user_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface updatePassword_updatePassword_user_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updatePassword_updatePassword_user_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: updatePassword_updatePassword_user_tos_request_user | null;
}

export interface updatePassword_updatePassword_user_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updatePassword_updatePassword_user_tos {
  __typename: "Send";
  id: string;
  account: updatePassword_updatePassword_user_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updatePassword_updatePassword_user_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: updatePassword_updatePassword_user_tos_toUser | null;
  request: updatePassword_updatePassword_user_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: updatePassword_updatePassword_user_tos_user | null;
}

export interface updatePassword_updatePassword_user {
  __typename: "User";
  id: string;
  accounts: updatePassword_updatePassword_user_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: updatePassword_updatePassword_user_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: updatePassword_updatePassword_user_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: updatePassword_updatePassword_user_froms[] | null;
  invites: updatePassword_updatePassword_user_invites[];
  lastName: string | null;
  members: updatePassword_updatePassword_user_members[];
  pendingInvites: updatePassword_updatePassword_user_pendingInvites[];
  permissions: updatePassword_updatePassword_user_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: updatePassword_updatePassword_user_requests[] | null;
  selectedAccount: updatePassword_updatePassword_user_selectedAccount | null;
  sends: updatePassword_updatePassword_user_sends[] | null;
  timezone: string | null;
  tos: updatePassword_updatePassword_user_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface updatePassword_updatePassword {
  __typename: "AuthPayload";
  token: string;
  user: updatePassword_updatePassword_user;
}

export interface updatePassword {
  updatePassword: updatePassword_updatePassword;
}

export interface updatePasswordVariables {
  data: UpdatePasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: validateEmail
// ====================================================

export interface validateEmail_validateEmail_user_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface validateEmail_validateEmail_user_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface validateEmail_validateEmail_user_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface validateEmail_validateEmail_user_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface validateEmail_validateEmail_user_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface validateEmail_validateEmail_user_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_business_froms {
  __typename: "Request";
  id: string;
  account: validateEmail_validateEmail_user_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: validateEmail_validateEmail_user_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: validateEmail_validateEmail_user_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: validateEmail_validateEmail_user_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: validateEmail_validateEmail_user_business_froms_user | null;
}

export interface validateEmail_validateEmail_user_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface validateEmail_validateEmail_user_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface validateEmail_validateEmail_user_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: validateEmail_validateEmail_user_business_members_user | null;
}

export interface validateEmail_validateEmail_user_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface validateEmail_validateEmail_user_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: validateEmail_validateEmail_user_business_pendingInvites_user | null;
}

export interface validateEmail_validateEmail_user_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface validateEmail_validateEmail_user_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: validateEmail_validateEmail_user_business_permissions_business | null;
}

export interface validateEmail_validateEmail_user_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface validateEmail_validateEmail_user_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface validateEmail_validateEmail_user_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface validateEmail_validateEmail_user_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_business_requests {
  __typename: "Request";
  id: string;
  account: validateEmail_validateEmail_user_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: validateEmail_validateEmail_user_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: validateEmail_validateEmail_user_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: validateEmail_validateEmail_user_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: validateEmail_validateEmail_user_business_requests_user | null;
}

export interface validateEmail_validateEmail_user_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface validateEmail_validateEmail_user_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface validateEmail_validateEmail_user_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface validateEmail_validateEmail_user_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface validateEmail_validateEmail_user_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: validateEmail_validateEmail_user_business_sends_request_user | null;
}

export interface validateEmail_validateEmail_user_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_business_sends {
  __typename: "Send";
  id: string;
  account: validateEmail_validateEmail_user_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: validateEmail_validateEmail_user_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: validateEmail_validateEmail_user_business_sends_toUser | null;
  request: validateEmail_validateEmail_user_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: validateEmail_validateEmail_user_business_sends_user | null;
}

export interface validateEmail_validateEmail_user_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface validateEmail_validateEmail_user_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface validateEmail_validateEmail_user_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface validateEmail_validateEmail_user_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: validateEmail_validateEmail_user_business_tos_request_user | null;
}

export interface validateEmail_validateEmail_user_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_business_tos {
  __typename: "Send";
  id: string;
  account: validateEmail_validateEmail_user_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: validateEmail_validateEmail_user_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: validateEmail_validateEmail_user_business_tos_toUser | null;
  request: validateEmail_validateEmail_user_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: validateEmail_validateEmail_user_business_tos_user | null;
}

export interface validateEmail_validateEmail_user_business {
  __typename: "User";
  id: string;
  accounts: validateEmail_validateEmail_user_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: validateEmail_validateEmail_user_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: validateEmail_validateEmail_user_business_froms[] | null;
  invites: validateEmail_validateEmail_user_business_invites[];
  lastName: string | null;
  members: validateEmail_validateEmail_user_business_members[];
  pendingInvites: validateEmail_validateEmail_user_business_pendingInvites[];
  permissions: validateEmail_validateEmail_user_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: validateEmail_validateEmail_user_business_requests[] | null;
  selectedAccount: validateEmail_validateEmail_user_business_selectedAccount | null;
  sends: validateEmail_validateEmail_user_business_sends[] | null;
  tos: validateEmail_validateEmail_user_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface validateEmail_validateEmail_user_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface validateEmail_validateEmail_user_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface validateEmail_validateEmail_user_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface validateEmail_validateEmail_user_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_froms {
  __typename: "Request";
  id: string;
  account: validateEmail_validateEmail_user_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: validateEmail_validateEmail_user_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: validateEmail_validateEmail_user_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: validateEmail_validateEmail_user_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: validateEmail_validateEmail_user_froms_user | null;
}

export interface validateEmail_validateEmail_user_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface validateEmail_validateEmail_user_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface validateEmail_validateEmail_user_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: validateEmail_validateEmail_user_members_user | null;
}

export interface validateEmail_validateEmail_user_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface validateEmail_validateEmail_user_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: validateEmail_validateEmail_user_pendingInvites_user | null;
}

export interface validateEmail_validateEmail_user_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface validateEmail_validateEmail_user_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: validateEmail_validateEmail_user_permissions_business | null;
}

export interface validateEmail_validateEmail_user_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface validateEmail_validateEmail_user_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface validateEmail_validateEmail_user_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface validateEmail_validateEmail_user_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_requests {
  __typename: "Request";
  id: string;
  account: validateEmail_validateEmail_user_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: validateEmail_validateEmail_user_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: validateEmail_validateEmail_user_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: validateEmail_validateEmail_user_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: validateEmail_validateEmail_user_requests_user | null;
}

export interface validateEmail_validateEmail_user_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface validateEmail_validateEmail_user_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface validateEmail_validateEmail_user_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface validateEmail_validateEmail_user_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface validateEmail_validateEmail_user_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: validateEmail_validateEmail_user_sends_request_user | null;
}

export interface validateEmail_validateEmail_user_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_sends {
  __typename: "Send";
  id: string;
  account: validateEmail_validateEmail_user_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: validateEmail_validateEmail_user_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: validateEmail_validateEmail_user_sends_toUser | null;
  request: validateEmail_validateEmail_user_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: validateEmail_validateEmail_user_sends_user | null;
}

export interface validateEmail_validateEmail_user_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface validateEmail_validateEmail_user_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface validateEmail_validateEmail_user_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface validateEmail_validateEmail_user_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: validateEmail_validateEmail_user_tos_request_user | null;
}

export interface validateEmail_validateEmail_user_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface validateEmail_validateEmail_user_tos {
  __typename: "Send";
  id: string;
  account: validateEmail_validateEmail_user_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: validateEmail_validateEmail_user_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: validateEmail_validateEmail_user_tos_toUser | null;
  request: validateEmail_validateEmail_user_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: validateEmail_validateEmail_user_tos_user | null;
}

export interface validateEmail_validateEmail_user {
  __typename: "User";
  id: string;
  accounts: validateEmail_validateEmail_user_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: validateEmail_validateEmail_user_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: validateEmail_validateEmail_user_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: validateEmail_validateEmail_user_froms[] | null;
  invites: validateEmail_validateEmail_user_invites[];
  lastName: string | null;
  members: validateEmail_validateEmail_user_members[];
  pendingInvites: validateEmail_validateEmail_user_pendingInvites[];
  permissions: validateEmail_validateEmail_user_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: validateEmail_validateEmail_user_requests[] | null;
  selectedAccount: validateEmail_validateEmail_user_selectedAccount | null;
  sends: validateEmail_validateEmail_user_sends[] | null;
  timezone: string | null;
  tos: validateEmail_validateEmail_user_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface validateEmail_validateEmail {
  __typename: "AuthPayload";
  token: string;
  user: validateEmail_validateEmail_user;
}

export interface validateEmail {
  validateEmail: validateEmail_validateEmail;
}

export interface validateEmailVariables {
  data: ValidateEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: me
// ====================================================

export interface me_me_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface me_me_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface me_me_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface me_me_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface me_me_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface me_me_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface me_me_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface me_me_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface me_me_business_froms {
  __typename: "Request";
  id: string;
  account: me_me_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: me_me_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: me_me_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: me_me_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: me_me_business_froms_user | null;
}

export interface me_me_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface me_me_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface me_me_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: me_me_business_members_user | null;
}

export interface me_me_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface me_me_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: me_me_business_pendingInvites_user | null;
}

export interface me_me_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface me_me_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: me_me_business_permissions_business | null;
}

export interface me_me_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface me_me_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface me_me_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface me_me_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface me_me_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface me_me_business_requests {
  __typename: "Request";
  id: string;
  account: me_me_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: me_me_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: me_me_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: me_me_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: me_me_business_requests_user | null;
}

export interface me_me_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface me_me_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface me_me_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface me_me_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface me_me_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface me_me_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: me_me_business_sends_request_user | null;
}

export interface me_me_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface me_me_business_sends {
  __typename: "Send";
  id: string;
  account: me_me_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: me_me_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: me_me_business_sends_toUser | null;
  request: me_me_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: me_me_business_sends_user | null;
}

export interface me_me_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface me_me_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface me_me_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface me_me_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface me_me_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: me_me_business_tos_request_user | null;
}

export interface me_me_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface me_me_business_tos {
  __typename: "Send";
  id: string;
  account: me_me_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: me_me_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: me_me_business_tos_toUser | null;
  request: me_me_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: me_me_business_tos_user | null;
}

export interface me_me_business {
  __typename: "User";
  id: string;
  accounts: me_me_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: me_me_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: me_me_business_froms[] | null;
  invites: me_me_business_invites[];
  lastName: string | null;
  members: me_me_business_members[];
  pendingInvites: me_me_business_pendingInvites[];
  permissions: me_me_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: me_me_business_requests[] | null;
  selectedAccount: me_me_business_selectedAccount | null;
  sends: me_me_business_sends[] | null;
  tos: me_me_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface me_me_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface me_me_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface me_me_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface me_me_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface me_me_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface me_me_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface me_me_froms {
  __typename: "Request";
  id: string;
  account: me_me_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: me_me_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: me_me_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: me_me_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: me_me_froms_user | null;
}

export interface me_me_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface me_me_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface me_me_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: me_me_members_user | null;
}

export interface me_me_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface me_me_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: me_me_pendingInvites_user | null;
}

export interface me_me_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface me_me_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: me_me_permissions_business | null;
}

export interface me_me_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface me_me_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface me_me_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface me_me_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface me_me_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface me_me_requests {
  __typename: "Request";
  id: string;
  account: me_me_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: me_me_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: me_me_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: me_me_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: me_me_requests_user | null;
}

export interface me_me_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface me_me_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface me_me_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface me_me_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface me_me_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface me_me_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: me_me_sends_request_user | null;
}

export interface me_me_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface me_me_sends {
  __typename: "Send";
  id: string;
  account: me_me_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: me_me_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: me_me_sends_toUser | null;
  request: me_me_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: me_me_sends_user | null;
}

export interface me_me_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface me_me_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface me_me_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface me_me_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface me_me_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: me_me_tos_request_user | null;
}

export interface me_me_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface me_me_tos {
  __typename: "Send";
  id: string;
  account: me_me_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: me_me_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: me_me_tos_toUser | null;
  request: me_me_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: me_me_tos_user | null;
}

export interface me_me {
  __typename: "User";
  id: string;
  accounts: me_me_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: me_me_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: me_me_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: me_me_froms[] | null;
  invites: me_me_invites[];
  lastName: string | null;
  members: me_me_members[];
  pendingInvites: me_me_pendingInvites[];
  permissions: me_me_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: me_me_requests[] | null;
  selectedAccount: me_me_selectedAccount | null;
  sends: me_me_sends[] | null;
  timezone: string | null;
  tos: me_me_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface me {
  me: me_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: business
// ====================================================

export interface business_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface business_business_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface business_business_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface business_business_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface business_business_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface business_business_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface business_business_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface business_business_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface business_business_business_froms {
  __typename: "Request";
  id: string;
  account: business_business_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: business_business_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: business_business_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: business_business_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: business_business_business_froms_user | null;
}

export interface business_business_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface business_business_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface business_business_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: business_business_business_members_user | null;
}

export interface business_business_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface business_business_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: business_business_business_pendingInvites_user | null;
}

export interface business_business_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface business_business_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: business_business_business_permissions_business | null;
}

export interface business_business_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface business_business_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface business_business_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface business_business_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface business_business_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface business_business_business_requests {
  __typename: "Request";
  id: string;
  account: business_business_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: business_business_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: business_business_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: business_business_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: business_business_business_requests_user | null;
}

export interface business_business_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface business_business_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface business_business_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface business_business_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface business_business_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface business_business_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: business_business_business_sends_request_user | null;
}

export interface business_business_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface business_business_business_sends {
  __typename: "Send";
  id: string;
  account: business_business_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: business_business_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: business_business_business_sends_toUser | null;
  request: business_business_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: business_business_business_sends_user | null;
}

export interface business_business_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface business_business_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface business_business_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface business_business_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface business_business_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: business_business_business_tos_request_user | null;
}

export interface business_business_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface business_business_business_tos {
  __typename: "Send";
  id: string;
  account: business_business_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: business_business_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: business_business_business_tos_toUser | null;
  request: business_business_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: business_business_business_tos_user | null;
}

export interface business_business_business {
  __typename: "User";
  id: string;
  accounts: business_business_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: business_business_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: business_business_business_froms[] | null;
  invites: business_business_business_invites[];
  lastName: string | null;
  members: business_business_business_members[];
  pendingInvites: business_business_business_pendingInvites[];
  permissions: business_business_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: business_business_business_requests[] | null;
  selectedAccount: business_business_business_selectedAccount | null;
  sends: business_business_business_sends[] | null;
  tos: business_business_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface business_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface business_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface business_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface business_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface business_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface business_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface business_business_froms {
  __typename: "Request";
  id: string;
  account: business_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: business_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: business_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: business_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: business_business_froms_user | null;
}

export interface business_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface business_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface business_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: business_business_members_user | null;
}

export interface business_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface business_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: business_business_pendingInvites_user | null;
}

export interface business_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface business_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: business_business_permissions_business | null;
}

export interface business_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface business_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface business_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface business_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface business_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface business_business_requests {
  __typename: "Request";
  id: string;
  account: business_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: business_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: business_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: business_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: business_business_requests_user | null;
}

export interface business_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface business_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface business_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface business_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface business_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface business_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: business_business_sends_request_user | null;
}

export interface business_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface business_business_sends {
  __typename: "Send";
  id: string;
  account: business_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: business_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: business_business_sends_toUser | null;
  request: business_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: business_business_sends_user | null;
}

export interface business_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface business_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface business_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface business_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface business_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: business_business_tos_request_user | null;
}

export interface business_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface business_business_tos {
  __typename: "Send";
  id: string;
  account: business_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: business_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: business_business_tos_toUser | null;
  request: business_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: business_business_tos_user | null;
}

export interface business_business {
  __typename: "User";
  id: string;
  accounts: business_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: business_business_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: business_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: business_business_froms[] | null;
  invites: business_business_invites[];
  lastName: string | null;
  members: business_business_members[];
  pendingInvites: business_business_pendingInvites[];
  permissions: business_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: business_business_requests[] | null;
  selectedAccount: business_business_selectedAccount | null;
  sends: business_business_sends[] | null;
  timezone: string | null;
  tos: business_business_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface business {
  business: business_business | null;
}

export interface businessVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUser
// ====================================================

export interface updateUser_updateUser_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface updateUser_updateUser_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface updateUser_updateUser_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface updateUser_updateUser_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface updateUser_updateUser_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface updateUser_updateUser_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_business_froms {
  __typename: "Request";
  id: string;
  account: updateUser_updateUser_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updateUser_updateUser_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: updateUser_updateUser_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: updateUser_updateUser_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: updateUser_updateUser_business_froms_user | null;
}

export interface updateUser_updateUser_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface updateUser_updateUser_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updateUser_updateUser_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: updateUser_updateUser_business_members_user | null;
}

export interface updateUser_updateUser_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface updateUser_updateUser_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: updateUser_updateUser_business_pendingInvites_user | null;
}

export interface updateUser_updateUser_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface updateUser_updateUser_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: updateUser_updateUser_business_permissions_business | null;
}

export interface updateUser_updateUser_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface updateUser_updateUser_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface updateUser_updateUser_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface updateUser_updateUser_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_business_requests {
  __typename: "Request";
  id: string;
  account: updateUser_updateUser_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updateUser_updateUser_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: updateUser_updateUser_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: updateUser_updateUser_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: updateUser_updateUser_business_requests_user | null;
}

export interface updateUser_updateUser_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface updateUser_updateUser_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface updateUser_updateUser_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface updateUser_updateUser_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updateUser_updateUser_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: updateUser_updateUser_business_sends_request_user | null;
}

export interface updateUser_updateUser_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_business_sends {
  __typename: "Send";
  id: string;
  account: updateUser_updateUser_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updateUser_updateUser_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: updateUser_updateUser_business_sends_toUser | null;
  request: updateUser_updateUser_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: updateUser_updateUser_business_sends_user | null;
}

export interface updateUser_updateUser_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface updateUser_updateUser_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface updateUser_updateUser_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updateUser_updateUser_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: updateUser_updateUser_business_tos_request_user | null;
}

export interface updateUser_updateUser_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_business_tos {
  __typename: "Send";
  id: string;
  account: updateUser_updateUser_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updateUser_updateUser_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: updateUser_updateUser_business_tos_toUser | null;
  request: updateUser_updateUser_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: updateUser_updateUser_business_tos_user | null;
}

export interface updateUser_updateUser_business {
  __typename: "User";
  id: string;
  accounts: updateUser_updateUser_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: updateUser_updateUser_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: updateUser_updateUser_business_froms[] | null;
  invites: updateUser_updateUser_business_invites[];
  lastName: string | null;
  members: updateUser_updateUser_business_members[];
  pendingInvites: updateUser_updateUser_business_pendingInvites[];
  permissions: updateUser_updateUser_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: updateUser_updateUser_business_requests[] | null;
  selectedAccount: updateUser_updateUser_business_selectedAccount | null;
  sends: updateUser_updateUser_business_sends[] | null;
  tos: updateUser_updateUser_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface updateUser_updateUser_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface updateUser_updateUser_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface updateUser_updateUser_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface updateUser_updateUser_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_froms {
  __typename: "Request";
  id: string;
  account: updateUser_updateUser_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updateUser_updateUser_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: updateUser_updateUser_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: updateUser_updateUser_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: updateUser_updateUser_froms_user | null;
}

export interface updateUser_updateUser_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface updateUser_updateUser_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updateUser_updateUser_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: updateUser_updateUser_members_user | null;
}

export interface updateUser_updateUser_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface updateUser_updateUser_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: updateUser_updateUser_pendingInvites_user | null;
}

export interface updateUser_updateUser_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface updateUser_updateUser_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: updateUser_updateUser_permissions_business | null;
}

export interface updateUser_updateUser_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface updateUser_updateUser_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface updateUser_updateUser_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface updateUser_updateUser_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_requests {
  __typename: "Request";
  id: string;
  account: updateUser_updateUser_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updateUser_updateUser_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: updateUser_updateUser_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: updateUser_updateUser_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: updateUser_updateUser_requests_user | null;
}

export interface updateUser_updateUser_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface updateUser_updateUser_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface updateUser_updateUser_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface updateUser_updateUser_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updateUser_updateUser_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: updateUser_updateUser_sends_request_user | null;
}

export interface updateUser_updateUser_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_sends {
  __typename: "Send";
  id: string;
  account: updateUser_updateUser_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updateUser_updateUser_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: updateUser_updateUser_sends_toUser | null;
  request: updateUser_updateUser_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: updateUser_updateUser_sends_user | null;
}

export interface updateUser_updateUser_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface updateUser_updateUser_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface updateUser_updateUser_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface updateUser_updateUser_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: updateUser_updateUser_tos_request_user | null;
}

export interface updateUser_updateUser_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface updateUser_updateUser_tos {
  __typename: "Send";
  id: string;
  account: updateUser_updateUser_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: updateUser_updateUser_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: updateUser_updateUser_tos_toUser | null;
  request: updateUser_updateUser_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: updateUser_updateUser_tos_user | null;
}

export interface updateUser_updateUser {
  __typename: "User";
  id: string;
  accounts: updateUser_updateUser_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: updateUser_updateUser_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: updateUser_updateUser_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: updateUser_updateUser_froms[] | null;
  invites: updateUser_updateUser_invites[];
  lastName: string | null;
  members: updateUser_updateUser_members[];
  pendingInvites: updateUser_updateUser_pendingInvites[];
  permissions: updateUser_updateUser_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: updateUser_updateUser_requests[] | null;
  selectedAccount: updateUser_updateUser_selectedAccount | null;
  sends: updateUser_updateUser_sends[] | null;
  timezone: string | null;
  tos: updateUser_updateUser_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface updateUser {
  updateUser: updateUser_updateUser | null;
}

export interface updateUserVariables {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: loginBusiness
// ====================================================

export interface loginBusiness_loginBusiness_user_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface loginBusiness_loginBusiness_user_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface loginBusiness_loginBusiness_user_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface loginBusiness_loginBusiness_user_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface loginBusiness_loginBusiness_user_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface loginBusiness_loginBusiness_user_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_business_froms {
  __typename: "Request";
  id: string;
  account: loginBusiness_loginBusiness_user_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: loginBusiness_loginBusiness_user_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: loginBusiness_loginBusiness_user_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: loginBusiness_loginBusiness_user_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: loginBusiness_loginBusiness_user_business_froms_user | null;
}

export interface loginBusiness_loginBusiness_user_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface loginBusiness_loginBusiness_user_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface loginBusiness_loginBusiness_user_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: loginBusiness_loginBusiness_user_business_members_user | null;
}

export interface loginBusiness_loginBusiness_user_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface loginBusiness_loginBusiness_user_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: loginBusiness_loginBusiness_user_business_pendingInvites_user | null;
}

export interface loginBusiness_loginBusiness_user_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface loginBusiness_loginBusiness_user_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: loginBusiness_loginBusiness_user_business_permissions_business | null;
}

export interface loginBusiness_loginBusiness_user_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface loginBusiness_loginBusiness_user_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface loginBusiness_loginBusiness_user_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface loginBusiness_loginBusiness_user_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_business_requests {
  __typename: "Request";
  id: string;
  account: loginBusiness_loginBusiness_user_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: loginBusiness_loginBusiness_user_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: loginBusiness_loginBusiness_user_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: loginBusiness_loginBusiness_user_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: loginBusiness_loginBusiness_user_business_requests_user | null;
}

export interface loginBusiness_loginBusiness_user_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface loginBusiness_loginBusiness_user_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface loginBusiness_loginBusiness_user_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface loginBusiness_loginBusiness_user_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface loginBusiness_loginBusiness_user_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: loginBusiness_loginBusiness_user_business_sends_request_user | null;
}

export interface loginBusiness_loginBusiness_user_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_business_sends {
  __typename: "Send";
  id: string;
  account: loginBusiness_loginBusiness_user_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: loginBusiness_loginBusiness_user_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: loginBusiness_loginBusiness_user_business_sends_toUser | null;
  request: loginBusiness_loginBusiness_user_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: loginBusiness_loginBusiness_user_business_sends_user | null;
}

export interface loginBusiness_loginBusiness_user_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface loginBusiness_loginBusiness_user_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface loginBusiness_loginBusiness_user_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface loginBusiness_loginBusiness_user_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: loginBusiness_loginBusiness_user_business_tos_request_user | null;
}

export interface loginBusiness_loginBusiness_user_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_business_tos {
  __typename: "Send";
  id: string;
  account: loginBusiness_loginBusiness_user_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: loginBusiness_loginBusiness_user_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: loginBusiness_loginBusiness_user_business_tos_toUser | null;
  request: loginBusiness_loginBusiness_user_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: loginBusiness_loginBusiness_user_business_tos_user | null;
}

export interface loginBusiness_loginBusiness_user_business {
  __typename: "User";
  id: string;
  accounts: loginBusiness_loginBusiness_user_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: loginBusiness_loginBusiness_user_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: loginBusiness_loginBusiness_user_business_froms[] | null;
  invites: loginBusiness_loginBusiness_user_business_invites[];
  lastName: string | null;
  members: loginBusiness_loginBusiness_user_business_members[];
  pendingInvites: loginBusiness_loginBusiness_user_business_pendingInvites[];
  permissions: loginBusiness_loginBusiness_user_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: loginBusiness_loginBusiness_user_business_requests[] | null;
  selectedAccount: loginBusiness_loginBusiness_user_business_selectedAccount | null;
  sends: loginBusiness_loginBusiness_user_business_sends[] | null;
  tos: loginBusiness_loginBusiness_user_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface loginBusiness_loginBusiness_user_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface loginBusiness_loginBusiness_user_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface loginBusiness_loginBusiness_user_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface loginBusiness_loginBusiness_user_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_froms {
  __typename: "Request";
  id: string;
  account: loginBusiness_loginBusiness_user_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: loginBusiness_loginBusiness_user_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: loginBusiness_loginBusiness_user_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: loginBusiness_loginBusiness_user_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: loginBusiness_loginBusiness_user_froms_user | null;
}

export interface loginBusiness_loginBusiness_user_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface loginBusiness_loginBusiness_user_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface loginBusiness_loginBusiness_user_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: loginBusiness_loginBusiness_user_members_user | null;
}

export interface loginBusiness_loginBusiness_user_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface loginBusiness_loginBusiness_user_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: loginBusiness_loginBusiness_user_pendingInvites_user | null;
}

export interface loginBusiness_loginBusiness_user_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface loginBusiness_loginBusiness_user_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: loginBusiness_loginBusiness_user_permissions_business | null;
}

export interface loginBusiness_loginBusiness_user_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface loginBusiness_loginBusiness_user_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface loginBusiness_loginBusiness_user_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface loginBusiness_loginBusiness_user_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_requests {
  __typename: "Request";
  id: string;
  account: loginBusiness_loginBusiness_user_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: loginBusiness_loginBusiness_user_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: loginBusiness_loginBusiness_user_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: loginBusiness_loginBusiness_user_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: loginBusiness_loginBusiness_user_requests_user | null;
}

export interface loginBusiness_loginBusiness_user_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface loginBusiness_loginBusiness_user_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface loginBusiness_loginBusiness_user_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface loginBusiness_loginBusiness_user_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface loginBusiness_loginBusiness_user_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: loginBusiness_loginBusiness_user_sends_request_user | null;
}

export interface loginBusiness_loginBusiness_user_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_sends {
  __typename: "Send";
  id: string;
  account: loginBusiness_loginBusiness_user_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: loginBusiness_loginBusiness_user_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: loginBusiness_loginBusiness_user_sends_toUser | null;
  request: loginBusiness_loginBusiness_user_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: loginBusiness_loginBusiness_user_sends_user | null;
}

export interface loginBusiness_loginBusiness_user_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface loginBusiness_loginBusiness_user_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface loginBusiness_loginBusiness_user_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface loginBusiness_loginBusiness_user_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: loginBusiness_loginBusiness_user_tos_request_user | null;
}

export interface loginBusiness_loginBusiness_user_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface loginBusiness_loginBusiness_user_tos {
  __typename: "Send";
  id: string;
  account: loginBusiness_loginBusiness_user_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: loginBusiness_loginBusiness_user_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: loginBusiness_loginBusiness_user_tos_toUser | null;
  request: loginBusiness_loginBusiness_user_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: loginBusiness_loginBusiness_user_tos_user | null;
}

export interface loginBusiness_loginBusiness_user {
  __typename: "User";
  id: string;
  accounts: loginBusiness_loginBusiness_user_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: loginBusiness_loginBusiness_user_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: loginBusiness_loginBusiness_user_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: loginBusiness_loginBusiness_user_froms[] | null;
  invites: loginBusiness_loginBusiness_user_invites[];
  lastName: string | null;
  members: loginBusiness_loginBusiness_user_members[];
  pendingInvites: loginBusiness_loginBusiness_user_pendingInvites[];
  permissions: loginBusiness_loginBusiness_user_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: loginBusiness_loginBusiness_user_requests[] | null;
  selectedAccount: loginBusiness_loginBusiness_user_selectedAccount | null;
  sends: loginBusiness_loginBusiness_user_sends[] | null;
  timezone: string | null;
  tos: loginBusiness_loginBusiness_user_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface loginBusiness_loginBusiness {
  __typename: "AuthPayload";
  token: string;
  user: loginBusiness_loginBusiness_user;
}

export interface loginBusiness {
  loginBusiness: loginBusiness_loginBusiness;
}

export interface loginBusinessVariables {
  data: LoginBusinessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: linkAccount
// ====================================================

export interface linkAccount_linkAccount_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface linkAccount_linkAccount_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface linkAccount_linkAccount_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface linkAccount_linkAccount_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface linkAccount_linkAccount_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface linkAccount_linkAccount_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_business_froms {
  __typename: "Request";
  id: string;
  account: linkAccount_linkAccount_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: linkAccount_linkAccount_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: linkAccount_linkAccount_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: linkAccount_linkAccount_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: linkAccount_linkAccount_business_froms_user | null;
}

export interface linkAccount_linkAccount_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface linkAccount_linkAccount_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface linkAccount_linkAccount_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: linkAccount_linkAccount_business_members_user | null;
}

export interface linkAccount_linkAccount_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface linkAccount_linkAccount_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: linkAccount_linkAccount_business_pendingInvites_user | null;
}

export interface linkAccount_linkAccount_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface linkAccount_linkAccount_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: linkAccount_linkAccount_business_permissions_business | null;
}

export interface linkAccount_linkAccount_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface linkAccount_linkAccount_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface linkAccount_linkAccount_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface linkAccount_linkAccount_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_business_requests {
  __typename: "Request";
  id: string;
  account: linkAccount_linkAccount_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: linkAccount_linkAccount_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: linkAccount_linkAccount_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: linkAccount_linkAccount_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: linkAccount_linkAccount_business_requests_user | null;
}

export interface linkAccount_linkAccount_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface linkAccount_linkAccount_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface linkAccount_linkAccount_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface linkAccount_linkAccount_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface linkAccount_linkAccount_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: linkAccount_linkAccount_business_sends_request_user | null;
}

export interface linkAccount_linkAccount_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_business_sends {
  __typename: "Send";
  id: string;
  account: linkAccount_linkAccount_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: linkAccount_linkAccount_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: linkAccount_linkAccount_business_sends_toUser | null;
  request: linkAccount_linkAccount_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: linkAccount_linkAccount_business_sends_user | null;
}

export interface linkAccount_linkAccount_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface linkAccount_linkAccount_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface linkAccount_linkAccount_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface linkAccount_linkAccount_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: linkAccount_linkAccount_business_tos_request_user | null;
}

export interface linkAccount_linkAccount_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_business_tos {
  __typename: "Send";
  id: string;
  account: linkAccount_linkAccount_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: linkAccount_linkAccount_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: linkAccount_linkAccount_business_tos_toUser | null;
  request: linkAccount_linkAccount_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: linkAccount_linkAccount_business_tos_user | null;
}

export interface linkAccount_linkAccount_business {
  __typename: "User";
  id: string;
  accounts: linkAccount_linkAccount_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: linkAccount_linkAccount_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: linkAccount_linkAccount_business_froms[] | null;
  invites: linkAccount_linkAccount_business_invites[];
  lastName: string | null;
  members: linkAccount_linkAccount_business_members[];
  pendingInvites: linkAccount_linkAccount_business_pendingInvites[];
  permissions: linkAccount_linkAccount_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: linkAccount_linkAccount_business_requests[] | null;
  selectedAccount: linkAccount_linkAccount_business_selectedAccount | null;
  sends: linkAccount_linkAccount_business_sends[] | null;
  tos: linkAccount_linkAccount_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface linkAccount_linkAccount_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface linkAccount_linkAccount_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface linkAccount_linkAccount_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface linkAccount_linkAccount_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_froms {
  __typename: "Request";
  id: string;
  account: linkAccount_linkAccount_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: linkAccount_linkAccount_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: linkAccount_linkAccount_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: linkAccount_linkAccount_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: linkAccount_linkAccount_froms_user | null;
}

export interface linkAccount_linkAccount_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface linkAccount_linkAccount_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface linkAccount_linkAccount_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: linkAccount_linkAccount_members_user | null;
}

export interface linkAccount_linkAccount_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface linkAccount_linkAccount_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: linkAccount_linkAccount_pendingInvites_user | null;
}

export interface linkAccount_linkAccount_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface linkAccount_linkAccount_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: linkAccount_linkAccount_permissions_business | null;
}

export interface linkAccount_linkAccount_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface linkAccount_linkAccount_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface linkAccount_linkAccount_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface linkAccount_linkAccount_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_requests {
  __typename: "Request";
  id: string;
  account: linkAccount_linkAccount_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: linkAccount_linkAccount_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: linkAccount_linkAccount_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: linkAccount_linkAccount_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: linkAccount_linkAccount_requests_user | null;
}

export interface linkAccount_linkAccount_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface linkAccount_linkAccount_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface linkAccount_linkAccount_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface linkAccount_linkAccount_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface linkAccount_linkAccount_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: linkAccount_linkAccount_sends_request_user | null;
}

export interface linkAccount_linkAccount_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_sends {
  __typename: "Send";
  id: string;
  account: linkAccount_linkAccount_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: linkAccount_linkAccount_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: linkAccount_linkAccount_sends_toUser | null;
  request: linkAccount_linkAccount_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: linkAccount_linkAccount_sends_user | null;
}

export interface linkAccount_linkAccount_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface linkAccount_linkAccount_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface linkAccount_linkAccount_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface linkAccount_linkAccount_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: linkAccount_linkAccount_tos_request_user | null;
}

export interface linkAccount_linkAccount_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface linkAccount_linkAccount_tos {
  __typename: "Send";
  id: string;
  account: linkAccount_linkAccount_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: linkAccount_linkAccount_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: linkAccount_linkAccount_tos_toUser | null;
  request: linkAccount_linkAccount_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: linkAccount_linkAccount_tos_user | null;
}

export interface linkAccount_linkAccount {
  __typename: "User";
  id: string;
  accounts: linkAccount_linkAccount_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: linkAccount_linkAccount_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: linkAccount_linkAccount_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: linkAccount_linkAccount_froms[] | null;
  invites: linkAccount_linkAccount_invites[];
  lastName: string | null;
  members: linkAccount_linkAccount_members[];
  pendingInvites: linkAccount_linkAccount_pendingInvites[];
  permissions: linkAccount_linkAccount_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: linkAccount_linkAccount_requests[] | null;
  selectedAccount: linkAccount_linkAccount_selectedAccount | null;
  sends: linkAccount_linkAccount_sends[] | null;
  timezone: string | null;
  tos: linkAccount_linkAccount_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

export interface linkAccount {
  linkAccount: linkAccount_linkAccount;
}

export interface linkAccountVariables {
  data: LinkAccountInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: inviteContact
// ====================================================

export interface inviteContact_inviteContact {
  __typename: "SuccessPayload";
  success: boolean;
}

export interface inviteContact {
  inviteContact: inviteContact_inviteContact;
}

export interface inviteContactVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: inviteMember
// ====================================================

export interface inviteMember_inviteMember {
  __typename: "SuccessPayload";
  success: boolean;
}

export interface inviteMember {
  inviteMember: inviteMember_inviteMember;
}

export interface inviteMemberVariables {
  data: InviteMemberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: validateToken
// ====================================================

export interface validateToken_validateToken {
  __typename: "SuccessPayload";
  success: boolean;
}

export interface validateToken {
  validateToken: validateToken_validateToken;
}

export interface validateTokenVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: closeAccount
// ====================================================

export interface closeAccount_closeAccount {
  __typename: "SuccessPayload";
  success: boolean;
}

export interface closeAccount {
  closeAccount: closeAccount_closeAccount;
}

export interface closeAccountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptInvite
// ====================================================

export interface acceptInvite_acceptInvite {
  __typename: "SuccessPayload";
  success: boolean;
}

export interface acceptInvite {
  acceptInvite: acceptInvite_acceptInvite;
}

export interface acceptInviteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkBalance
// ====================================================

export interface checkBalance {
  checkBalance: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allUserData
// ====================================================

export interface allUserData_allUserData {
  __typename: "AllUserData";
  address: string | null;
  address2: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  createdAt: any | null;
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  postalCode: string | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
  hasBankAccount: boolean | null;
  hasTransacted: boolean | null;
}

export interface allUserData {
  allUserData: (allUserData_allUserData | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AccountPayload
// ====================================================

export interface AccountPayload {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RequestPayload
// ====================================================

export interface RequestPayload_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface RequestPayload_createdBy {
  __typename: "User";
  id: string;
}

export interface RequestPayload_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface RequestPayload_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface RequestPayload_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface RequestPayload {
  __typename: "Request";
  id: string;
  account: RequestPayload_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: RequestPayload_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: RequestPayload_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: RequestPayload_send | null;
  status: StatusType;
  updatedAt: any;
  user: RequestPayload_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SendPayload
// ====================================================

export interface SendPayload_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface SendPayload_createdBy {
  __typename: "User";
  id: string;
}

export interface SendPayload_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface SendPayload_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface SendPayload_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: SendPayload_request_user | null;
}

export interface SendPayload_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface SendPayload {
  __typename: "Send";
  id: string;
  account: SendPayload_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: SendPayload_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: SendPayload_toUser | null;
  request: SendPayload_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: SendPayload_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BusinessPayload
// ====================================================

export interface BusinessPayload_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface BusinessPayload_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface BusinessPayload_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface BusinessPayload_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface BusinessPayload_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface BusinessPayload_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface BusinessPayload_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface BusinessPayload_froms {
  __typename: "Request";
  id: string;
  account: BusinessPayload_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: BusinessPayload_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: BusinessPayload_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: BusinessPayload_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: BusinessPayload_froms_user | null;
}

export interface BusinessPayload_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface BusinessPayload_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface BusinessPayload_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: BusinessPayload_members_user | null;
}

export interface BusinessPayload_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface BusinessPayload_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: BusinessPayload_pendingInvites_user | null;
}

export interface BusinessPayload_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface BusinessPayload_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: BusinessPayload_permissions_business | null;
}

export interface BusinessPayload_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface BusinessPayload_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface BusinessPayload_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface BusinessPayload_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface BusinessPayload_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface BusinessPayload_requests {
  __typename: "Request";
  id: string;
  account: BusinessPayload_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: BusinessPayload_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: BusinessPayload_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: BusinessPayload_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: BusinessPayload_requests_user | null;
}

export interface BusinessPayload_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface BusinessPayload_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface BusinessPayload_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface BusinessPayload_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface BusinessPayload_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface BusinessPayload_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: BusinessPayload_sends_request_user | null;
}

export interface BusinessPayload_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface BusinessPayload_sends {
  __typename: "Send";
  id: string;
  account: BusinessPayload_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: BusinessPayload_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: BusinessPayload_sends_toUser | null;
  request: BusinessPayload_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: BusinessPayload_sends_user | null;
}

export interface BusinessPayload_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface BusinessPayload_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface BusinessPayload_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface BusinessPayload_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface BusinessPayload_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: BusinessPayload_tos_request_user | null;
}

export interface BusinessPayload_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface BusinessPayload_tos {
  __typename: "Send";
  id: string;
  account: BusinessPayload_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: BusinessPayload_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: BusinessPayload_tos_toUser | null;
  request: BusinessPayload_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: BusinessPayload_tos_user | null;
}

export interface BusinessPayload {
  __typename: "User";
  id: string;
  accounts: BusinessPayload_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: BusinessPayload_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: BusinessPayload_froms[] | null;
  invites: BusinessPayload_invites[];
  lastName: string | null;
  members: BusinessPayload_members[];
  pendingInvites: BusinessPayload_pendingInvites[];
  permissions: BusinessPayload_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: BusinessPayload_requests[] | null;
  selectedAccount: BusinessPayload_selectedAccount | null;
  sends: BusinessPayload_sends[] | null;
  tos: BusinessPayload_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserPayload
// ====================================================

export interface UserPayload_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface UserPayload_business_accounts {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface UserPayload_business_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface UserPayload_business_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface UserPayload_business_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface UserPayload_business_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface UserPayload_business_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface UserPayload_business_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface UserPayload_business_froms {
  __typename: "Request";
  id: string;
  account: UserPayload_business_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: UserPayload_business_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: UserPayload_business_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: UserPayload_business_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: UserPayload_business_froms_user | null;
}

export interface UserPayload_business_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface UserPayload_business_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface UserPayload_business_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: UserPayload_business_members_user | null;
}

export interface UserPayload_business_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface UserPayload_business_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: UserPayload_business_pendingInvites_user | null;
}

export interface UserPayload_business_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface UserPayload_business_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: UserPayload_business_permissions_business | null;
}

export interface UserPayload_business_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface UserPayload_business_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface UserPayload_business_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface UserPayload_business_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface UserPayload_business_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface UserPayload_business_requests {
  __typename: "Request";
  id: string;
  account: UserPayload_business_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: UserPayload_business_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: UserPayload_business_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: UserPayload_business_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: UserPayload_business_requests_user | null;
}

export interface UserPayload_business_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface UserPayload_business_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface UserPayload_business_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface UserPayload_business_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface UserPayload_business_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface UserPayload_business_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: UserPayload_business_sends_request_user | null;
}

export interface UserPayload_business_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface UserPayload_business_sends {
  __typename: "Send";
  id: string;
  account: UserPayload_business_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: UserPayload_business_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: UserPayload_business_sends_toUser | null;
  request: UserPayload_business_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: UserPayload_business_sends_user | null;
}

export interface UserPayload_business_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface UserPayload_business_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface UserPayload_business_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface UserPayload_business_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface UserPayload_business_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: UserPayload_business_tos_request_user | null;
}

export interface UserPayload_business_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface UserPayload_business_tos {
  __typename: "Send";
  id: string;
  account: UserPayload_business_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: UserPayload_business_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: UserPayload_business_tos_toUser | null;
  request: UserPayload_business_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: UserPayload_business_tos_user | null;
}

export interface UserPayload_business {
  __typename: "User";
  id: string;
  accounts: UserPayload_business_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: UserPayload_business_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: UserPayload_business_froms[] | null;
  invites: UserPayload_business_invites[];
  lastName: string | null;
  members: UserPayload_business_members[];
  pendingInvites: UserPayload_business_pendingInvites[];
  permissions: UserPayload_business_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: UserPayload_business_requests[] | null;
  selectedAccount: UserPayload_business_selectedAccount | null;
  sends: UserPayload_business_sends[] | null;
  tos: UserPayload_business_tos[] | null;
  state: string | null;
  timezone: string | null;
  website: string | null;
}

export interface UserPayload_contacts {
  __typename: "User";
  id: string;
  businessName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface UserPayload_froms_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface UserPayload_froms_createdBy {
  __typename: "User";
  id: string;
}

export interface UserPayload_froms_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface UserPayload_froms_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface UserPayload_froms_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface UserPayload_froms {
  __typename: "Request";
  id: string;
  account: UserPayload_froms_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: UserPayload_froms_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: UserPayload_froms_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: UserPayload_froms_send | null;
  status: StatusType;
  updatedAt: any;
  user: UserPayload_froms_user | null;
}

export interface UserPayload_invites {
  __typename: "Invite";
  id: string;
  for: string | null;
  status: StatusType | null;
}

export interface UserPayload_members_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface UserPayload_members {
  __typename: "Permission";
  id: string;
  for: string | null;
  status: StatusType | null;
  permissions: PermissionType[];
  user: UserPayload_members_user | null;
}

export interface UserPayload_pendingInvites_user {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface UserPayload_pendingInvites {
  __typename: "Invite";
  id: string;
  status: StatusType | null;
  user: UserPayload_pendingInvites_user | null;
}

export interface UserPayload_permissions_business {
  __typename: "User";
  id: string;
  customerType: CustomerType | null;
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
}

export interface UserPayload_permissions {
  __typename: "Permission";
  id: string;
  permissions: PermissionType[];
  business: UserPayload_permissions_business | null;
}

export interface UserPayload_requests_account {
  __typename: "Account";
  id: string;
  mask: string | null;
  name: string | null;
  institution: string | null;
}

export interface UserPayload_requests_createdBy {
  __typename: "User";
  id: string;
}

export interface UserPayload_requests_fromUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface UserPayload_requests_send {
  __typename: "Send";
  id: string;
  createdAt: any;
  processedAt: any | null;
  status: StatusType | null;
}

export interface UserPayload_requests_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: string | null;
}

export interface UserPayload_requests {
  __typename: "Request";
  id: string;
  account: UserPayload_requests_account | null;
  amount: string;
  canceledAt: any | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: UserPayload_requests_createdBy | null;
  declinedAt: any | null;
  for: string;
  from: string;
  fromPaysFee: boolean | null;
  fromUser: UserPayload_requests_fromUser | null;
  processedAt: any | null;
  requestedAt: any | null;
  send: UserPayload_requests_send | null;
  status: StatusType;
  updatedAt: any;
  user: UserPayload_requests_user | null;
}

export interface UserPayload_selectedAccount {
  __typename: "Account";
  id: string;
  accountId: string;
  institution: string | null;
  mask: string | null;
  name: string | null;
  type: AccountType | null;
}

export interface UserPayload_sends_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface UserPayload_sends_createdBy {
  __typename: "User";
  id: string;
}

export interface UserPayload_sends_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface UserPayload_sends_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface UserPayload_sends_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: UserPayload_sends_request_user | null;
}

export interface UserPayload_sends_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface UserPayload_sends {
  __typename: "Send";
  id: string;
  account: UserPayload_sends_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: UserPayload_sends_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: UserPayload_sends_toUser | null;
  request: UserPayload_sends_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: UserPayload_sends_user | null;
}

export interface UserPayload_tos_account {
  __typename: "Account";
  id: string;
  name: string | null;
  mask: string | null;
  institution: string | null;
}

export interface UserPayload_tos_createdBy {
  __typename: "User";
  id: string;
}

export interface UserPayload_tos_toUser {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface UserPayload_tos_request_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface UserPayload_tos_request {
  __typename: "Request";
  id: string;
  fromPaysFee: boolean | null;
  status: StatusType;
  user: UserPayload_tos_request_user | null;
}

export interface UserPayload_tos_user {
  __typename: "User";
  id: string;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  profilePicture: string | null;
}

export interface UserPayload_tos {
  __typename: "Send";
  id: string;
  account: UserPayload_tos_account | null;
  amount: string;
  canCancel: boolean | null;
  completedAt: any | null;
  createdAt: any;
  createdBy: UserPayload_tos_createdBy | null;
  for: string | null;
  paymentDate: any | null;
  processedAt: any | null;
  to: string | null;
  toUser: UserPayload_tos_toUser | null;
  request: UserPayload_tos_request | null;
  status: StatusType | null;
  updatedAt: any;
  user: UserPayload_tos_user | null;
}

export interface UserPayload {
  __typename: "User";
  id: string;
  accounts: UserPayload_accounts[] | null;
  address: string | null;
  address2: string | null;
  balance: string | null;
  business: UserPayload_business | null;
  businessName: string | null;
  businessType: BusinessType | null;
  city: string | null;
  contacts: UserPayload_contacts[] | null;
  customerStatus: CustomerStatus[];
  customerType: CustomerType | null;
  dateOfBirth: any | null;
  description: string | null;
  email: string | null;
  firstName: string | null;
  froms: UserPayload_froms[] | null;
  invites: UserPayload_invites[];
  lastName: string | null;
  members: UserPayload_members[];
  pendingInvites: UserPayload_pendingInvites[];
  permissions: UserPayload_permissions[];
  plaidPublicToken: string | null;
  postalCode: string | null;
  profilePicture: string | null;
  requests: UserPayload_requests[] | null;
  selectedAccount: UserPayload_selectedAccount | null;
  sends: UserPayload_sends[] | null;
  timezone: string | null;
  tos: UserPayload_tos[] | null;
  state: string | null;
  token: string | null;
  verificationData: any | null;
  website: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountType {
  Checking = "Checking",
  Pending = "Pending",
  Savings = "Savings",
}

export enum BusinessType {
  Corporation = "Corporation",
  LLC = "LLC",
  Partnership = "Partnership",
  SoleProprietor = "SoleProprietor",
}

export enum CustomerStatus {
  CertifyBeneficialOwnership = "CertifyBeneficialOwnership",
  Document = "Document",
  DocumentBusiness = "DocumentBusiness",
  DocumentControllerAndBusiness = "DocumentControllerAndBusiness",
  EmailUnverified = "EmailUnverified",
  Retry = "Retry",
  VerifyBeneficialOwners = "VerifyBeneficialOwners",
}

export enum CustomerType {
  Business = "Business",
  Closed = "Closed",
  Member = "Member",
  Personal = "Personal",
  Unverified = "Unverified",
}

export enum PermissionType {
  Admin = "Admin",
  EditBankAccounts = "EditBankAccounts",
  InviteUsers = "InviteUsers",
  ViewUserActivity = "ViewUserActivity",
}

export enum StatusType {
  Canceled = "Canceled",
  Completed = "Completed",
  Created = "Created",
  Declined = "Declined",
  Failed = "Failed",
  Pending = "Pending",
  Processing = "Processing",
  Requested = "Requested",
  Scheduled = "Scheduled",
}

export interface AccountCreateManyWithoutUserInput {
  create?: AccountCreateWithoutUserInput[] | null;
  connect?: AccountWhereUniqueInput[] | null;
}

export interface AccountCreateOneWithoutRequestsInput {
  create?: AccountCreateWithoutRequestsInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateOneWithoutSelectedInput {
  create?: AccountCreateWithoutSelectedInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateOneWithoutSendsInput {
  create?: AccountCreateWithoutSendsInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateWithoutRequestsInput {
  accountId: string;
  fundingSource?: string | null;
  id?: string | null;
  institution?: string | null;
  mask?: string | null;
  name?: string | null;
  type?: AccountType | null;
  selected?: UserCreateOneWithoutSelectedAccountInput | null;
  user?: UserCreateOneWithoutAccountsInput | null;
  sends?: SendCreateManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutSelectedInput {
  accountId: string;
  fundingSource?: string | null;
  id?: string | null;
  institution?: string | null;
  mask?: string | null;
  name?: string | null;
  type?: AccountType | null;
  user?: UserCreateOneWithoutAccountsInput | null;
  requests?: RequestCreateManyWithoutAccountInput | null;
  sends?: SendCreateManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutSendsInput {
  accountId: string;
  fundingSource?: string | null;
  id?: string | null;
  institution?: string | null;
  mask?: string | null;
  name?: string | null;
  type?: AccountType | null;
  selected?: UserCreateOneWithoutSelectedAccountInput | null;
  user?: UserCreateOneWithoutAccountsInput | null;
  requests?: RequestCreateManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutUserInput {
  accountId: string;
  fundingSource?: string | null;
  id?: string | null;
  institution?: string | null;
  mask?: string | null;
  name?: string | null;
  type?: AccountType | null;
  selected?: UserCreateOneWithoutSelectedAccountInput | null;
  requests?: RequestCreateManyWithoutAccountInput | null;
  sends?: SendCreateManyWithoutAccountInput | null;
}

export interface AccountScalarWhereInput {
  AND?: AccountScalarWhereInput[] | null;
  OR?: AccountScalarWhereInput[] | null;
  NOT?: AccountScalarWhereInput[] | null;
  accountId?: string | null;
  accountId_not?: string | null;
  accountId_in?: string[] | null;
  accountId_not_in?: string[] | null;
  accountId_lt?: string | null;
  accountId_lte?: string | null;
  accountId_gt?: string | null;
  accountId_gte?: string | null;
  accountId_contains?: string | null;
  accountId_not_contains?: string | null;
  accountId_starts_with?: string | null;
  accountId_not_starts_with?: string | null;
  accountId_ends_with?: string | null;
  accountId_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  fundingSource?: string | null;
  fundingSource_not?: string | null;
  fundingSource_in?: string[] | null;
  fundingSource_not_in?: string[] | null;
  fundingSource_lt?: string | null;
  fundingSource_lte?: string | null;
  fundingSource_gt?: string | null;
  fundingSource_gte?: string | null;
  fundingSource_contains?: string | null;
  fundingSource_not_contains?: string | null;
  fundingSource_starts_with?: string | null;
  fundingSource_not_starts_with?: string | null;
  fundingSource_ends_with?: string | null;
  fundingSource_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  institution?: string | null;
  institution_not?: string | null;
  institution_in?: string[] | null;
  institution_not_in?: string[] | null;
  institution_lt?: string | null;
  institution_lte?: string | null;
  institution_gt?: string | null;
  institution_gte?: string | null;
  institution_contains?: string | null;
  institution_not_contains?: string | null;
  institution_starts_with?: string | null;
  institution_not_starts_with?: string | null;
  institution_ends_with?: string | null;
  institution_not_ends_with?: string | null;
  mask?: string | null;
  mask_not?: string | null;
  mask_in?: string[] | null;
  mask_not_in?: string[] | null;
  mask_lt?: string | null;
  mask_lte?: string | null;
  mask_gt?: string | null;
  mask_gte?: string | null;
  mask_contains?: string | null;
  mask_not_contains?: string | null;
  mask_starts_with?: string | null;
  mask_not_starts_with?: string | null;
  mask_ends_with?: string | null;
  mask_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  type?: AccountType | null;
  type_not?: AccountType | null;
  type_in?: AccountType[] | null;
  type_not_in?: AccountType[] | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
}

export interface AccountUpdateManyDataInput {
  accountId?: string | null;
  fundingSource?: string | null;
  institution?: string | null;
  mask?: string | null;
  name?: string | null;
  type?: AccountType | null;
}

export interface AccountUpdateManyWithWhereNestedInput {
  where: AccountScalarWhereInput;
  data: AccountUpdateManyDataInput;
}

export interface AccountUpdateManyWithoutUserInput {
  create?: AccountCreateWithoutUserInput[] | null;
  connect?: AccountWhereUniqueInput[] | null;
  set?: AccountWhereUniqueInput[] | null;
  disconnect?: AccountWhereUniqueInput[] | null;
  delete?: AccountWhereUniqueInput[] | null;
  update?: AccountUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: AccountUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: AccountScalarWhereInput[] | null;
  upsert?: AccountUpsertWithWhereUniqueWithoutUserInput[] | null;
}

export interface AccountUpdateOneWithoutRequestsInput {
  create?: AccountCreateWithoutRequestsInput | null;
  connect?: AccountWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: AccountUpdateWithoutRequestsDataInput | null;
  upsert?: AccountUpsertWithoutRequestsInput | null;
}

export interface AccountUpdateOneWithoutSelectedInput {
  create?: AccountCreateWithoutSelectedInput | null;
  connect?: AccountWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: AccountUpdateWithoutSelectedDataInput | null;
  upsert?: AccountUpsertWithoutSelectedInput | null;
}

export interface AccountUpdateOneWithoutSendsInput {
  create?: AccountCreateWithoutSendsInput | null;
  connect?: AccountWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: AccountUpdateWithoutSendsDataInput | null;
  upsert?: AccountUpsertWithoutSendsInput | null;
}

export interface AccountUpdateWithWhereUniqueWithoutUserInput {
  where: AccountWhereUniqueInput;
  data: AccountUpdateWithoutUserDataInput;
}

export interface AccountUpdateWithoutRequestsDataInput {
  accountId?: string | null;
  fundingSource?: string | null;
  institution?: string | null;
  mask?: string | null;
  name?: string | null;
  type?: AccountType | null;
  selected?: UserUpdateOneWithoutSelectedAccountInput | null;
  user?: UserUpdateOneWithoutAccountsInput | null;
  sends?: SendUpdateManyWithoutAccountInput | null;
}

export interface AccountUpdateWithoutSelectedDataInput {
  accountId?: string | null;
  fundingSource?: string | null;
  institution?: string | null;
  mask?: string | null;
  name?: string | null;
  type?: AccountType | null;
  user?: UserUpdateOneWithoutAccountsInput | null;
  requests?: RequestUpdateManyWithoutAccountInput | null;
  sends?: SendUpdateManyWithoutAccountInput | null;
}

export interface AccountUpdateWithoutSendsDataInput {
  accountId?: string | null;
  fundingSource?: string | null;
  institution?: string | null;
  mask?: string | null;
  name?: string | null;
  type?: AccountType | null;
  selected?: UserUpdateOneWithoutSelectedAccountInput | null;
  user?: UserUpdateOneWithoutAccountsInput | null;
  requests?: RequestUpdateManyWithoutAccountInput | null;
}

export interface AccountUpdateWithoutUserDataInput {
  accountId?: string | null;
  fundingSource?: string | null;
  institution?: string | null;
  mask?: string | null;
  name?: string | null;
  type?: AccountType | null;
  selected?: UserUpdateOneWithoutSelectedAccountInput | null;
  requests?: RequestUpdateManyWithoutAccountInput | null;
  sends?: SendUpdateManyWithoutAccountInput | null;
}

export interface AccountUpsertWithWhereUniqueWithoutUserInput {
  where: AccountWhereUniqueInput;
  update: AccountUpdateWithoutUserDataInput;
  create: AccountCreateWithoutUserInput;
}

export interface AccountUpsertWithoutRequestsInput {
  update: AccountUpdateWithoutRequestsDataInput;
  create: AccountCreateWithoutRequestsInput;
}

export interface AccountUpsertWithoutSelectedInput {
  update: AccountUpdateWithoutSelectedDataInput;
  create: AccountCreateWithoutSelectedInput;
}

export interface AccountUpsertWithoutSendsInput {
  update: AccountUpdateWithoutSendsDataInput;
  create: AccountCreateWithoutSendsInput;
}

export interface AccountWhereUniqueInput {
  accountId?: string | null;
  fundingSource?: string | null;
  id?: string | null;
}

export interface InviteCreateManyWithoutForUserInput {
  create?: InviteCreateWithoutForUserInput[] | null;
  connect?: InviteWhereUniqueInput[] | null;
}

export interface InviteCreateManyWithoutUserInput {
  create?: InviteCreateWithoutUserInput[] | null;
  connect?: InviteWhereUniqueInput[] | null;
}

export interface InviteCreateWithoutForUserInput {
  id?: string | null;
  status?: StatusType | null;
  for?: string | null;
  user?: UserCreateOneWithoutInvitesInput | null;
}

export interface InviteCreateWithoutUserInput {
  id?: string | null;
  status?: StatusType | null;
  for?: string | null;
  forUser?: UserCreateOneWithoutPendingInvitesInput | null;
}

export interface InviteMemberInput {
  email: string;
  permissions: PermissionType[];
}

export interface InviteScalarWhereInput {
  AND?: InviteScalarWhereInput[] | null;
  OR?: InviteScalarWhereInput[] | null;
  NOT?: InviteScalarWhereInput[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  status?: StatusType | null;
  status_not?: StatusType | null;
  status_in?: StatusType[] | null;
  status_not_in?: StatusType[] | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  for?: string | null;
  for_not?: string | null;
  for_in?: string[] | null;
  for_not_in?: string[] | null;
  for_lt?: string | null;
  for_lte?: string | null;
  for_gt?: string | null;
  for_gte?: string | null;
  for_contains?: string | null;
  for_not_contains?: string | null;
  for_starts_with?: string | null;
  for_not_starts_with?: string | null;
  for_ends_with?: string | null;
  for_not_ends_with?: string | null;
}

export interface InviteUpdateManyDataInput {
  status?: StatusType | null;
  for?: string | null;
}

export interface InviteUpdateManyWithWhereNestedInput {
  where: InviteScalarWhereInput;
  data: InviteUpdateManyDataInput;
}

export interface InviteUpdateManyWithoutForUserInput {
  create?: InviteCreateWithoutForUserInput[] | null;
  connect?: InviteWhereUniqueInput[] | null;
  set?: InviteWhereUniqueInput[] | null;
  disconnect?: InviteWhereUniqueInput[] | null;
  delete?: InviteWhereUniqueInput[] | null;
  update?: InviteUpdateWithWhereUniqueWithoutForUserInput[] | null;
  updateMany?: InviteUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: InviteScalarWhereInput[] | null;
  upsert?: InviteUpsertWithWhereUniqueWithoutForUserInput[] | null;
}

export interface InviteUpdateManyWithoutUserInput {
  create?: InviteCreateWithoutUserInput[] | null;
  connect?: InviteWhereUniqueInput[] | null;
  set?: InviteWhereUniqueInput[] | null;
  disconnect?: InviteWhereUniqueInput[] | null;
  delete?: InviteWhereUniqueInput[] | null;
  update?: InviteUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: InviteUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: InviteScalarWhereInput[] | null;
  upsert?: InviteUpsertWithWhereUniqueWithoutUserInput[] | null;
}

export interface InviteUpdateWithWhereUniqueWithoutForUserInput {
  where: InviteWhereUniqueInput;
  data: InviteUpdateWithoutForUserDataInput;
}

export interface InviteUpdateWithWhereUniqueWithoutUserInput {
  where: InviteWhereUniqueInput;
  data: InviteUpdateWithoutUserDataInput;
}

export interface InviteUpdateWithoutForUserDataInput {
  status?: StatusType | null;
  for?: string | null;
  user?: UserUpdateOneWithoutInvitesInput | null;
}

export interface InviteUpdateWithoutUserDataInput {
  status?: StatusType | null;
  for?: string | null;
  forUser?: UserUpdateOneWithoutPendingInvitesInput | null;
}

export interface InviteUpsertWithWhereUniqueWithoutForUserInput {
  where: InviteWhereUniqueInput;
  update: InviteUpdateWithoutForUserDataInput;
  create: InviteCreateWithoutForUserInput;
}

export interface InviteUpsertWithWhereUniqueWithoutUserInput {
  where: InviteWhereUniqueInput;
  update: InviteUpdateWithoutUserDataInput;
  create: InviteCreateWithoutUserInput;
}

export interface InviteWhereUniqueInput {
  id?: string | null;
}

export interface LinkAccountInput {
  accountId: string;
  institution: string;
  mask: string;
  name: string;
  publicToken: string;
  type: string;
}

export interface LoginBusinessInput {
  business: string;
}

export interface LoginInput {
  email: string;
  password: string;
  token?: string | null;
  recaptcha?: string | null;
}

export interface PermissionCreateManyWithoutBusinessInput {
  create?: PermissionCreateWithoutBusinessInput[] | null;
  connect?: PermissionWhereUniqueInput[] | null;
}

export interface PermissionCreateManyWithoutUserInput {
  create?: PermissionCreateWithoutUserInput[] | null;
  connect?: PermissionWhereUniqueInput[] | null;
}

export interface PermissionCreateWithoutBusinessInput {
  id?: string | null;
  status?: StatusType | null;
  for?: string | null;
  permissions?: PermissionCreatepermissionsInput | null;
  user?: UserCreateOneWithoutPermissionsInput | null;
}

export interface PermissionCreateWithoutUserInput {
  id?: string | null;
  status?: StatusType | null;
  for?: string | null;
  permissions?: PermissionCreatepermissionsInput | null;
  business?: UserCreateOneWithoutMembersInput | null;
}

export interface PermissionCreatepermissionsInput {
  set?: PermissionType[] | null;
}

export interface PermissionScalarWhereInput {
  AND?: PermissionScalarWhereInput[] | null;
  OR?: PermissionScalarWhereInput[] | null;
  NOT?: PermissionScalarWhereInput[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  status?: StatusType | null;
  status_not?: StatusType | null;
  status_in?: StatusType[] | null;
  status_not_in?: StatusType[] | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  for?: string | null;
  for_not?: string | null;
  for_in?: string[] | null;
  for_not_in?: string[] | null;
  for_lt?: string | null;
  for_lte?: string | null;
  for_gt?: string | null;
  for_gte?: string | null;
  for_contains?: string | null;
  for_not_contains?: string | null;
  for_starts_with?: string | null;
  for_not_starts_with?: string | null;
  for_ends_with?: string | null;
  for_not_ends_with?: string | null;
}

export interface PermissionUpdateManyDataInput {
  status?: StatusType | null;
  for?: string | null;
  permissions?: PermissionUpdatepermissionsInput | null;
}

export interface PermissionUpdateManyWithWhereNestedInput {
  where: PermissionScalarWhereInput;
  data: PermissionUpdateManyDataInput;
}

export interface PermissionUpdateManyWithoutBusinessInput {
  create?: PermissionCreateWithoutBusinessInput[] | null;
  connect?: PermissionWhereUniqueInput[] | null;
  set?: PermissionWhereUniqueInput[] | null;
  disconnect?: PermissionWhereUniqueInput[] | null;
  delete?: PermissionWhereUniqueInput[] | null;
  update?: PermissionUpdateWithWhereUniqueWithoutBusinessInput[] | null;
  updateMany?: PermissionUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: PermissionScalarWhereInput[] | null;
  upsert?: PermissionUpsertWithWhereUniqueWithoutBusinessInput[] | null;
}

export interface PermissionUpdateManyWithoutUserInput {
  create?: PermissionCreateWithoutUserInput[] | null;
  connect?: PermissionWhereUniqueInput[] | null;
  set?: PermissionWhereUniqueInput[] | null;
  disconnect?: PermissionWhereUniqueInput[] | null;
  delete?: PermissionWhereUniqueInput[] | null;
  update?: PermissionUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: PermissionUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: PermissionScalarWhereInput[] | null;
  upsert?: PermissionUpsertWithWhereUniqueWithoutUserInput[] | null;
}

export interface PermissionUpdateWithWhereUniqueWithoutBusinessInput {
  where: PermissionWhereUniqueInput;
  data: PermissionUpdateWithoutBusinessDataInput;
}

export interface PermissionUpdateWithWhereUniqueWithoutUserInput {
  where: PermissionWhereUniqueInput;
  data: PermissionUpdateWithoutUserDataInput;
}

export interface PermissionUpdateWithoutBusinessDataInput {
  status?: StatusType | null;
  for?: string | null;
  permissions?: PermissionUpdatepermissionsInput | null;
  user?: UserUpdateOneWithoutPermissionsInput | null;
}

export interface PermissionUpdateWithoutUserDataInput {
  status?: StatusType | null;
  for?: string | null;
  permissions?: PermissionUpdatepermissionsInput | null;
  business?: UserUpdateOneWithoutMembersInput | null;
}

export interface PermissionUpdatepermissionsInput {
  set?: PermissionType[] | null;
}

export interface PermissionUpsertWithWhereUniqueWithoutBusinessInput {
  where: PermissionWhereUniqueInput;
  update: PermissionUpdateWithoutBusinessDataInput;
  create: PermissionCreateWithoutBusinessInput;
}

export interface PermissionUpsertWithWhereUniqueWithoutUserInput {
  where: PermissionWhereUniqueInput;
  update: PermissionUpdateWithoutUserDataInput;
  create: PermissionCreateWithoutUserInput;
}

export interface PermissionWhereUniqueInput {
  id?: string | null;
}

export interface RequestCreateInput {
  amount: string;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for: string;
  from: string;
  fromPaysFee?: boolean | null;
  id?: string | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  account?: AccountCreateOneWithoutRequestsInput | null;
  createdBy?: UserCreateOneWithoutRequestsCreatedInput | null;
  fromUser?: UserCreateOneWithoutFromsInput | null;
  send?: SendCreateOneWithoutRequestInput | null;
  user?: UserCreateOneWithoutRequestsInput | null;
}

export interface RequestCreateManyWithoutAccountInput {
  create?: RequestCreateWithoutAccountInput[] | null;
  connect?: RequestWhereUniqueInput[] | null;
}

export interface RequestCreateManyWithoutCreatedByInput {
  create?: RequestCreateWithoutCreatedByInput[] | null;
  connect?: RequestWhereUniqueInput[] | null;
}

export interface RequestCreateManyWithoutFromUserInput {
  create?: RequestCreateWithoutFromUserInput[] | null;
  connect?: RequestWhereUniqueInput[] | null;
}

export interface RequestCreateManyWithoutUserInput {
  create?: RequestCreateWithoutUserInput[] | null;
  connect?: RequestWhereUniqueInput[] | null;
}

export interface RequestCreateOneWithoutSendInput {
  create?: RequestCreateWithoutSendInput | null;
  connect?: RequestWhereUniqueInput | null;
}

export interface RequestCreateWithoutAccountInput {
  amount: string;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for: string;
  from: string;
  fromPaysFee?: boolean | null;
  id?: string | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  createdBy?: UserCreateOneWithoutRequestsCreatedInput | null;
  fromUser?: UserCreateOneWithoutFromsInput | null;
  send?: SendCreateOneWithoutRequestInput | null;
  user?: UserCreateOneWithoutRequestsInput | null;
}

export interface RequestCreateWithoutCreatedByInput {
  amount: string;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for: string;
  from: string;
  fromPaysFee?: boolean | null;
  id?: string | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  account?: AccountCreateOneWithoutRequestsInput | null;
  fromUser?: UserCreateOneWithoutFromsInput | null;
  send?: SendCreateOneWithoutRequestInput | null;
  user?: UserCreateOneWithoutRequestsInput | null;
}

export interface RequestCreateWithoutFromUserInput {
  amount: string;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for: string;
  from: string;
  fromPaysFee?: boolean | null;
  id?: string | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  account?: AccountCreateOneWithoutRequestsInput | null;
  createdBy?: UserCreateOneWithoutRequestsCreatedInput | null;
  send?: SendCreateOneWithoutRequestInput | null;
  user?: UserCreateOneWithoutRequestsInput | null;
}

export interface RequestCreateWithoutSendInput {
  amount: string;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for: string;
  from: string;
  fromPaysFee?: boolean | null;
  id?: string | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  account?: AccountCreateOneWithoutRequestsInput | null;
  createdBy?: UserCreateOneWithoutRequestsCreatedInput | null;
  fromUser?: UserCreateOneWithoutFromsInput | null;
  user?: UserCreateOneWithoutRequestsInput | null;
}

export interface RequestCreateWithoutUserInput {
  amount: string;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for: string;
  from: string;
  fromPaysFee?: boolean | null;
  id?: string | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  account?: AccountCreateOneWithoutRequestsInput | null;
  createdBy?: UserCreateOneWithoutRequestsCreatedInput | null;
  fromUser?: UserCreateOneWithoutFromsInput | null;
  send?: SendCreateOneWithoutRequestInput | null;
}

export interface RequestScalarWhereInput {
  AND?: RequestScalarWhereInput[] | null;
  OR?: RequestScalarWhereInput[] | null;
  NOT?: RequestScalarWhereInput[] | null;
  amount?: string | null;
  amount_not?: string | null;
  amount_in?: string[] | null;
  amount_not_in?: string[] | null;
  amount_lt?: string | null;
  amount_lte?: string | null;
  amount_gt?: string | null;
  amount_gte?: string | null;
  amount_contains?: string | null;
  amount_not_contains?: string | null;
  amount_starts_with?: string | null;
  amount_not_starts_with?: string | null;
  amount_ends_with?: string | null;
  amount_not_ends_with?: string | null;
  canceledAt?: any | null;
  canceledAt_not?: any | null;
  canceledAt_in?: any[] | null;
  canceledAt_not_in?: any[] | null;
  canceledAt_lt?: any | null;
  canceledAt_lte?: any | null;
  canceledAt_gt?: any | null;
  canceledAt_gte?: any | null;
  completedAt?: any | null;
  completedAt_not?: any | null;
  completedAt_in?: any[] | null;
  completedAt_not_in?: any[] | null;
  completedAt_lt?: any | null;
  completedAt_lte?: any | null;
  completedAt_gt?: any | null;
  completedAt_gte?: any | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  declinedAt?: any | null;
  declinedAt_not?: any | null;
  declinedAt_in?: any[] | null;
  declinedAt_not_in?: any[] | null;
  declinedAt_lt?: any | null;
  declinedAt_lte?: any | null;
  declinedAt_gt?: any | null;
  declinedAt_gte?: any | null;
  for?: string | null;
  for_not?: string | null;
  for_in?: string[] | null;
  for_not_in?: string[] | null;
  for_lt?: string | null;
  for_lte?: string | null;
  for_gt?: string | null;
  for_gte?: string | null;
  for_contains?: string | null;
  for_not_contains?: string | null;
  for_starts_with?: string | null;
  for_not_starts_with?: string | null;
  for_ends_with?: string | null;
  for_not_ends_with?: string | null;
  from?: string | null;
  from_not?: string | null;
  from_in?: string[] | null;
  from_not_in?: string[] | null;
  from_lt?: string | null;
  from_lte?: string | null;
  from_gt?: string | null;
  from_gte?: string | null;
  from_contains?: string | null;
  from_not_contains?: string | null;
  from_starts_with?: string | null;
  from_not_starts_with?: string | null;
  from_ends_with?: string | null;
  from_not_ends_with?: string | null;
  fromPaysFee?: boolean | null;
  fromPaysFee_not?: boolean | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  processedAt?: any | null;
  processedAt_not?: any | null;
  processedAt_in?: any[] | null;
  processedAt_not_in?: any[] | null;
  processedAt_lt?: any | null;
  processedAt_lte?: any | null;
  processedAt_gt?: any | null;
  processedAt_gte?: any | null;
  requestedAt?: any | null;
  requestedAt_not?: any | null;
  requestedAt_in?: any[] | null;
  requestedAt_not_in?: any[] | null;
  requestedAt_lt?: any | null;
  requestedAt_lte?: any | null;
  requestedAt_gt?: any | null;
  requestedAt_gte?: any | null;
  status?: StatusType | null;
  status_not?: StatusType | null;
  status_in?: StatusType[] | null;
  status_not_in?: StatusType[] | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
}

export interface RequestUpdateInput {
  amount?: string | null;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for?: string | null;
  from?: string | null;
  fromPaysFee?: boolean | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  account?: AccountUpdateOneWithoutRequestsInput | null;
  createdBy?: UserUpdateOneWithoutRequestsCreatedInput | null;
  fromUser?: UserUpdateOneWithoutFromsInput | null;
  send?: SendUpdateOneWithoutRequestInput | null;
  user?: UserUpdateOneWithoutRequestsInput | null;
}

export interface RequestUpdateManyDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for?: string | null;
  from?: string | null;
  fromPaysFee?: boolean | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
}

export interface RequestUpdateManyWithWhereNestedInput {
  where: RequestScalarWhereInput;
  data: RequestUpdateManyDataInput;
}

export interface RequestUpdateManyWithoutAccountInput {
  create?: RequestCreateWithoutAccountInput[] | null;
  connect?: RequestWhereUniqueInput[] | null;
  set?: RequestWhereUniqueInput[] | null;
  disconnect?: RequestWhereUniqueInput[] | null;
  delete?: RequestWhereUniqueInput[] | null;
  update?: RequestUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: RequestUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: RequestScalarWhereInput[] | null;
  upsert?: RequestUpsertWithWhereUniqueWithoutAccountInput[] | null;
}

export interface RequestUpdateManyWithoutCreatedByInput {
  create?: RequestCreateWithoutCreatedByInput[] | null;
  connect?: RequestWhereUniqueInput[] | null;
  set?: RequestWhereUniqueInput[] | null;
  disconnect?: RequestWhereUniqueInput[] | null;
  delete?: RequestWhereUniqueInput[] | null;
  update?: RequestUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: RequestUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: RequestScalarWhereInput[] | null;
  upsert?: RequestUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
}

export interface RequestUpdateManyWithoutFromUserInput {
  create?: RequestCreateWithoutFromUserInput[] | null;
  connect?: RequestWhereUniqueInput[] | null;
  set?: RequestWhereUniqueInput[] | null;
  disconnect?: RequestWhereUniqueInput[] | null;
  delete?: RequestWhereUniqueInput[] | null;
  update?: RequestUpdateWithWhereUniqueWithoutFromUserInput[] | null;
  updateMany?: RequestUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: RequestScalarWhereInput[] | null;
  upsert?: RequestUpsertWithWhereUniqueWithoutFromUserInput[] | null;
}

export interface RequestUpdateManyWithoutUserInput {
  create?: RequestCreateWithoutUserInput[] | null;
  connect?: RequestWhereUniqueInput[] | null;
  set?: RequestWhereUniqueInput[] | null;
  disconnect?: RequestWhereUniqueInput[] | null;
  delete?: RequestWhereUniqueInput[] | null;
  update?: RequestUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: RequestUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: RequestScalarWhereInput[] | null;
  upsert?: RequestUpsertWithWhereUniqueWithoutUserInput[] | null;
}

export interface RequestUpdateOneWithoutSendInput {
  create?: RequestCreateWithoutSendInput | null;
  connect?: RequestWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: RequestUpdateWithoutSendDataInput | null;
  upsert?: RequestUpsertWithoutSendInput | null;
}

export interface RequestUpdateWithWhereUniqueWithoutAccountInput {
  where: RequestWhereUniqueInput;
  data: RequestUpdateWithoutAccountDataInput;
}

export interface RequestUpdateWithWhereUniqueWithoutCreatedByInput {
  where: RequestWhereUniqueInput;
  data: RequestUpdateWithoutCreatedByDataInput;
}

export interface RequestUpdateWithWhereUniqueWithoutFromUserInput {
  where: RequestWhereUniqueInput;
  data: RequestUpdateWithoutFromUserDataInput;
}

export interface RequestUpdateWithWhereUniqueWithoutUserInput {
  where: RequestWhereUniqueInput;
  data: RequestUpdateWithoutUserDataInput;
}

export interface RequestUpdateWithoutAccountDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for?: string | null;
  from?: string | null;
  fromPaysFee?: boolean | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  createdBy?: UserUpdateOneWithoutRequestsCreatedInput | null;
  fromUser?: UserUpdateOneWithoutFromsInput | null;
  send?: SendUpdateOneWithoutRequestInput | null;
  user?: UserUpdateOneWithoutRequestsInput | null;
}

export interface RequestUpdateWithoutCreatedByDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for?: string | null;
  from?: string | null;
  fromPaysFee?: boolean | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  account?: AccountUpdateOneWithoutRequestsInput | null;
  fromUser?: UserUpdateOneWithoutFromsInput | null;
  send?: SendUpdateOneWithoutRequestInput | null;
  user?: UserUpdateOneWithoutRequestsInput | null;
}

export interface RequestUpdateWithoutFromUserDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for?: string | null;
  from?: string | null;
  fromPaysFee?: boolean | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  account?: AccountUpdateOneWithoutRequestsInput | null;
  createdBy?: UserUpdateOneWithoutRequestsCreatedInput | null;
  send?: SendUpdateOneWithoutRequestInput | null;
  user?: UserUpdateOneWithoutRequestsInput | null;
}

export interface RequestUpdateWithoutSendDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for?: string | null;
  from?: string | null;
  fromPaysFee?: boolean | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  account?: AccountUpdateOneWithoutRequestsInput | null;
  createdBy?: UserUpdateOneWithoutRequestsCreatedInput | null;
  fromUser?: UserUpdateOneWithoutFromsInput | null;
  user?: UserUpdateOneWithoutRequestsInput | null;
}

export interface RequestUpdateWithoutUserDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  completedAt?: any | null;
  declinedAt?: any | null;
  for?: string | null;
  from?: string | null;
  fromPaysFee?: boolean | null;
  processedAt?: any | null;
  requestedAt?: any | null;
  status?: StatusType | null;
  account?: AccountUpdateOneWithoutRequestsInput | null;
  createdBy?: UserUpdateOneWithoutRequestsCreatedInput | null;
  fromUser?: UserUpdateOneWithoutFromsInput | null;
  send?: SendUpdateOneWithoutRequestInput | null;
}

export interface RequestUpsertWithWhereUniqueWithoutAccountInput {
  where: RequestWhereUniqueInput;
  update: RequestUpdateWithoutAccountDataInput;
  create: RequestCreateWithoutAccountInput;
}

export interface RequestUpsertWithWhereUniqueWithoutCreatedByInput {
  where: RequestWhereUniqueInput;
  update: RequestUpdateWithoutCreatedByDataInput;
  create: RequestCreateWithoutCreatedByInput;
}

export interface RequestUpsertWithWhereUniqueWithoutFromUserInput {
  where: RequestWhereUniqueInput;
  update: RequestUpdateWithoutFromUserDataInput;
  create: RequestCreateWithoutFromUserInput;
}

export interface RequestUpsertWithWhereUniqueWithoutUserInput {
  where: RequestWhereUniqueInput;
  update: RequestUpdateWithoutUserDataInput;
  create: RequestCreateWithoutUserInput;
}

export interface RequestUpsertWithoutSendInput {
  update: RequestUpdateWithoutSendDataInput;
  create: RequestCreateWithoutSendInput;
}

export interface RequestWhereUniqueInput {
  id?: string | null;
}

export interface ResetPasswordInput {
  email: string;
  recaptcha: string;
}

export interface SendCreateInput {
  amount: string;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  id?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  account?: AccountCreateOneWithoutSendsInput | null;
  createdBy?: UserCreateOneWithoutSendsCreatedInput | null;
  request?: RequestCreateOneWithoutSendInput | null;
  toUser?: UserCreateOneWithoutTosInput | null;
  user?: UserCreateOneWithoutSendsInput | null;
}

export interface SendCreateManyWithoutAccountInput {
  create?: SendCreateWithoutAccountInput[] | null;
  connect?: SendWhereUniqueInput[] | null;
}

export interface SendCreateManyWithoutCreatedByInput {
  create?: SendCreateWithoutCreatedByInput[] | null;
  connect?: SendWhereUniqueInput[] | null;
}

export interface SendCreateManyWithoutToUserInput {
  create?: SendCreateWithoutToUserInput[] | null;
  connect?: SendWhereUniqueInput[] | null;
}

export interface SendCreateManyWithoutUserInput {
  create?: SendCreateWithoutUserInput[] | null;
  connect?: SendWhereUniqueInput[] | null;
}

export interface SendCreateOneWithoutRequestInput {
  create?: SendCreateWithoutRequestInput | null;
  connect?: SendWhereUniqueInput | null;
}

export interface SendCreateWithoutAccountInput {
  amount: string;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  id?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  createdBy?: UserCreateOneWithoutSendsCreatedInput | null;
  request?: RequestCreateOneWithoutSendInput | null;
  toUser?: UserCreateOneWithoutTosInput | null;
  user?: UserCreateOneWithoutSendsInput | null;
}

export interface SendCreateWithoutCreatedByInput {
  amount: string;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  id?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  account?: AccountCreateOneWithoutSendsInput | null;
  request?: RequestCreateOneWithoutSendInput | null;
  toUser?: UserCreateOneWithoutTosInput | null;
  user?: UserCreateOneWithoutSendsInput | null;
}

export interface SendCreateWithoutRequestInput {
  amount: string;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  id?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  account?: AccountCreateOneWithoutSendsInput | null;
  createdBy?: UserCreateOneWithoutSendsCreatedInput | null;
  toUser?: UserCreateOneWithoutTosInput | null;
  user?: UserCreateOneWithoutSendsInput | null;
}

export interface SendCreateWithoutToUserInput {
  amount: string;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  id?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  account?: AccountCreateOneWithoutSendsInput | null;
  createdBy?: UserCreateOneWithoutSendsCreatedInput | null;
  request?: RequestCreateOneWithoutSendInput | null;
  user?: UserCreateOneWithoutSendsInput | null;
}

export interface SendCreateWithoutUserInput {
  amount: string;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  id?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  account?: AccountCreateOneWithoutSendsInput | null;
  createdBy?: UserCreateOneWithoutSendsCreatedInput | null;
  request?: RequestCreateOneWithoutSendInput | null;
  toUser?: UserCreateOneWithoutTosInput | null;
}

export interface SendScalarWhereInput {
  AND?: SendScalarWhereInput[] | null;
  OR?: SendScalarWhereInput[] | null;
  NOT?: SendScalarWhereInput[] | null;
  amount?: string | null;
  amount_not?: string | null;
  amount_in?: string[] | null;
  amount_not_in?: string[] | null;
  amount_lt?: string | null;
  amount_lte?: string | null;
  amount_gt?: string | null;
  amount_gte?: string | null;
  amount_contains?: string | null;
  amount_not_contains?: string | null;
  amount_starts_with?: string | null;
  amount_not_starts_with?: string | null;
  amount_ends_with?: string | null;
  amount_not_ends_with?: string | null;
  canceledAt?: any | null;
  canceledAt_not?: any | null;
  canceledAt_in?: any[] | null;
  canceledAt_not_in?: any[] | null;
  canceledAt_lt?: any | null;
  canceledAt_lte?: any | null;
  canceledAt_gt?: any | null;
  canceledAt_gte?: any | null;
  canCancel?: boolean | null;
  canCancel_not?: boolean | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  completedAt?: any | null;
  completedAt_not?: any | null;
  completedAt_in?: any[] | null;
  completedAt_not_in?: any[] | null;
  completedAt_lt?: any | null;
  completedAt_lte?: any | null;
  completedAt_gt?: any | null;
  completedAt_gte?: any | null;
  for?: string | null;
  for_not?: string | null;
  for_in?: string[] | null;
  for_not_in?: string[] | null;
  for_lt?: string | null;
  for_lte?: string | null;
  for_gt?: string | null;
  for_gte?: string | null;
  for_contains?: string | null;
  for_not_contains?: string | null;
  for_starts_with?: string | null;
  for_not_starts_with?: string | null;
  for_ends_with?: string | null;
  for_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  paymentDate?: any | null;
  paymentDate_not?: any | null;
  paymentDate_in?: any[] | null;
  paymentDate_not_in?: any[] | null;
  paymentDate_lt?: any | null;
  paymentDate_lte?: any | null;
  paymentDate_gt?: any | null;
  paymentDate_gte?: any | null;
  processedAt?: any | null;
  processedAt_not?: any | null;
  processedAt_in?: any[] | null;
  processedAt_not_in?: any[] | null;
  processedAt_lt?: any | null;
  processedAt_lte?: any | null;
  processedAt_gt?: any | null;
  processedAt_gte?: any | null;
  status?: StatusType | null;
  status_not?: StatusType | null;
  status_in?: StatusType[] | null;
  status_not_in?: StatusType[] | null;
  to?: string | null;
  to_not?: string | null;
  to_in?: string[] | null;
  to_not_in?: string[] | null;
  to_lt?: string | null;
  to_lte?: string | null;
  to_gt?: string | null;
  to_gte?: string | null;
  to_contains?: string | null;
  to_not_contains?: string | null;
  to_starts_with?: string | null;
  to_not_starts_with?: string | null;
  to_ends_with?: string | null;
  to_not_ends_with?: string | null;
  transferId?: string | null;
  transferId_not?: string | null;
  transferId_in?: string[] | null;
  transferId_not_in?: string[] | null;
  transferId_lt?: string | null;
  transferId_lte?: string | null;
  transferId_gt?: string | null;
  transferId_gte?: string | null;
  transferId_contains?: string | null;
  transferId_not_contains?: string | null;
  transferId_starts_with?: string | null;
  transferId_not_starts_with?: string | null;
  transferId_ends_with?: string | null;
  transferId_not_ends_with?: string | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
}

export interface SendUpdateInput {
  amount?: string | null;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  account?: AccountUpdateOneWithoutSendsInput | null;
  createdBy?: UserUpdateOneWithoutSendsCreatedInput | null;
  request?: RequestUpdateOneWithoutSendInput | null;
  toUser?: UserUpdateOneWithoutTosInput | null;
  user?: UserUpdateOneWithoutSendsInput | null;
}

export interface SendUpdateManyDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
}

export interface SendUpdateManyWithWhereNestedInput {
  where: SendScalarWhereInput;
  data: SendUpdateManyDataInput;
}

export interface SendUpdateManyWithoutAccountInput {
  create?: SendCreateWithoutAccountInput[] | null;
  connect?: SendWhereUniqueInput[] | null;
  set?: SendWhereUniqueInput[] | null;
  disconnect?: SendWhereUniqueInput[] | null;
  delete?: SendWhereUniqueInput[] | null;
  update?: SendUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: SendUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: SendScalarWhereInput[] | null;
  upsert?: SendUpsertWithWhereUniqueWithoutAccountInput[] | null;
}

export interface SendUpdateManyWithoutCreatedByInput {
  create?: SendCreateWithoutCreatedByInput[] | null;
  connect?: SendWhereUniqueInput[] | null;
  set?: SendWhereUniqueInput[] | null;
  disconnect?: SendWhereUniqueInput[] | null;
  delete?: SendWhereUniqueInput[] | null;
  update?: SendUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: SendUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: SendScalarWhereInput[] | null;
  upsert?: SendUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
}

export interface SendUpdateManyWithoutToUserInput {
  create?: SendCreateWithoutToUserInput[] | null;
  connect?: SendWhereUniqueInput[] | null;
  set?: SendWhereUniqueInput[] | null;
  disconnect?: SendWhereUniqueInput[] | null;
  delete?: SendWhereUniqueInput[] | null;
  update?: SendUpdateWithWhereUniqueWithoutToUserInput[] | null;
  updateMany?: SendUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: SendScalarWhereInput[] | null;
  upsert?: SendUpsertWithWhereUniqueWithoutToUserInput[] | null;
}

export interface SendUpdateManyWithoutUserInput {
  create?: SendCreateWithoutUserInput[] | null;
  connect?: SendWhereUniqueInput[] | null;
  set?: SendWhereUniqueInput[] | null;
  disconnect?: SendWhereUniqueInput[] | null;
  delete?: SendWhereUniqueInput[] | null;
  update?: SendUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: SendUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: SendScalarWhereInput[] | null;
  upsert?: SendUpsertWithWhereUniqueWithoutUserInput[] | null;
}

export interface SendUpdateOneWithoutRequestInput {
  create?: SendCreateWithoutRequestInput | null;
  connect?: SendWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SendUpdateWithoutRequestDataInput | null;
  upsert?: SendUpsertWithoutRequestInput | null;
}

export interface SendUpdateWithWhereUniqueWithoutAccountInput {
  where: SendWhereUniqueInput;
  data: SendUpdateWithoutAccountDataInput;
}

export interface SendUpdateWithWhereUniqueWithoutCreatedByInput {
  where: SendWhereUniqueInput;
  data: SendUpdateWithoutCreatedByDataInput;
}

export interface SendUpdateWithWhereUniqueWithoutToUserInput {
  where: SendWhereUniqueInput;
  data: SendUpdateWithoutToUserDataInput;
}

export interface SendUpdateWithWhereUniqueWithoutUserInput {
  where: SendWhereUniqueInput;
  data: SendUpdateWithoutUserDataInput;
}

export interface SendUpdateWithoutAccountDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  createdBy?: UserUpdateOneWithoutSendsCreatedInput | null;
  request?: RequestUpdateOneWithoutSendInput | null;
  toUser?: UserUpdateOneWithoutTosInput | null;
  user?: UserUpdateOneWithoutSendsInput | null;
}

export interface SendUpdateWithoutCreatedByDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  account?: AccountUpdateOneWithoutSendsInput | null;
  request?: RequestUpdateOneWithoutSendInput | null;
  toUser?: UserUpdateOneWithoutTosInput | null;
  user?: UserUpdateOneWithoutSendsInput | null;
}

export interface SendUpdateWithoutRequestDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  account?: AccountUpdateOneWithoutSendsInput | null;
  createdBy?: UserUpdateOneWithoutSendsCreatedInput | null;
  toUser?: UserUpdateOneWithoutTosInput | null;
  user?: UserUpdateOneWithoutSendsInput | null;
}

export interface SendUpdateWithoutToUserDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  account?: AccountUpdateOneWithoutSendsInput | null;
  createdBy?: UserUpdateOneWithoutSendsCreatedInput | null;
  request?: RequestUpdateOneWithoutSendInput | null;
  user?: UserUpdateOneWithoutSendsInput | null;
}

export interface SendUpdateWithoutUserDataInput {
  amount?: string | null;
  canceledAt?: any | null;
  canCancel?: boolean | null;
  completedAt?: any | null;
  for?: string | null;
  paymentDate?: any | null;
  processedAt?: any | null;
  status?: StatusType | null;
  to?: string | null;
  transferId?: string | null;
  account?: AccountUpdateOneWithoutSendsInput | null;
  createdBy?: UserUpdateOneWithoutSendsCreatedInput | null;
  request?: RequestUpdateOneWithoutSendInput | null;
  toUser?: UserUpdateOneWithoutTosInput | null;
}

export interface SendUpsertWithWhereUniqueWithoutAccountInput {
  where: SendWhereUniqueInput;
  update: SendUpdateWithoutAccountDataInput;
  create: SendCreateWithoutAccountInput;
}

export interface SendUpsertWithWhereUniqueWithoutCreatedByInput {
  where: SendWhereUniqueInput;
  update: SendUpdateWithoutCreatedByDataInput;
  create: SendCreateWithoutCreatedByInput;
}

export interface SendUpsertWithWhereUniqueWithoutToUserInput {
  where: SendWhereUniqueInput;
  update: SendUpdateWithoutToUserDataInput;
  create: SendCreateWithoutToUserInput;
}

export interface SendUpsertWithWhereUniqueWithoutUserInput {
  where: SendWhereUniqueInput;
  update: SendUpdateWithoutUserDataInput;
  create: SendCreateWithoutUserInput;
}

export interface SendUpsertWithoutRequestInput {
  update: SendUpdateWithoutRequestDataInput;
  create: SendCreateWithoutRequestInput;
}

export interface SendWhereUniqueInput {
  id?: string | null;
  transferId?: string | null;
}

export interface SignupAndPayInput {
  accountId: string;
  businessName?: string | null;
  email: string;
  firstName: string;
  institution: string;
  lastName: string;
  mask: string;
  name: string;
  password: string;
  publicToken: string;
  requestId?: string | null;
  sendId?: string | null;
  timezone: string;
  recaptcha: string;
  type: string;
}

export interface SignupInput {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  password?: string | null;
  idToken?: string | null;
  token?: string | null;
  recaptcha: string;
}

export interface UpdatePasswordInput {
  newPassword: string;
  token: string;
  recaptcha: string;
}

export interface UserCreateInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateManyInput {
  create?: UserCreateInput[] | null;
  connect?: UserWhereUniqueInput[] | null;
}

export interface UserCreateOneWithoutAccountsInput {
  create?: UserCreateWithoutAccountsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutFromsInput {
  create?: UserCreateWithoutFromsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutInvitesInput {
  create?: UserCreateWithoutInvitesInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutMembersInput {
  create?: UserCreateWithoutMembersInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutPendingInvitesInput {
  create?: UserCreateWithoutPendingInvitesInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutPermissionsInput {
  create?: UserCreateWithoutPermissionsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutRequestsCreatedInput {
  create?: UserCreateWithoutRequestsCreatedInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutRequestsInput {
  create?: UserCreateWithoutRequestsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutSelectedAccountInput {
  create?: UserCreateWithoutSelectedAccountInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutSendsCreatedInput {
  create?: UserCreateWithoutSendsCreatedInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutSendsInput {
  create?: UserCreateWithoutSendsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutTosInput {
  create?: UserCreateWithoutTosInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateWithoutAccountsInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutFromsInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutInvitesInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutMembersInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutPendingInvitesInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutPermissionsInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutRequestsCreatedInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutRequestsInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutSelectedAccountInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutSendsCreatedInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutSendsInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
  tos?: SendCreateManyWithoutToUserInput | null;
}

export interface UserCreateWithoutTosInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType: CustomerType;
  dateOfBirth?: any | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserCreatecustomerStatusInput | null;
  ips?: UserCreateipsInput | null;
  accounts?: AccountCreateManyWithoutUserInput | null;
  contacts?: UserCreateManyInput | null;
  froms?: RequestCreateManyWithoutFromUserInput | null;
  invites?: InviteCreateManyWithoutUserInput | null;
  members?: PermissionCreateManyWithoutBusinessInput | null;
  pendingInvites?: InviteCreateManyWithoutForUserInput | null;
  permissions?: PermissionCreateManyWithoutUserInput | null;
  requests?: RequestCreateManyWithoutUserInput | null;
  requestsCreated?: RequestCreateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountCreateOneWithoutSelectedInput | null;
  sends?: SendCreateManyWithoutUserInput | null;
  sendsCreated?: SendCreateManyWithoutCreatedByInput | null;
}

export interface UserCreatecustomerStatusInput {
  set?: CustomerStatus[] | null;
}

export interface UserCreateipsInput {
  set?: string[] | null;
}

export interface UserScalarWhereInput {
  AND?: UserScalarWhereInput[] | null;
  OR?: UserScalarWhereInput[] | null;
  NOT?: UserScalarWhereInput[] | null;
  address?: string | null;
  address_not?: string | null;
  address_in?: string[] | null;
  address_not_in?: string[] | null;
  address_lt?: string | null;
  address_lte?: string | null;
  address_gt?: string | null;
  address_gte?: string | null;
  address_contains?: string | null;
  address_not_contains?: string | null;
  address_starts_with?: string | null;
  address_not_starts_with?: string | null;
  address_ends_with?: string | null;
  address_not_ends_with?: string | null;
  address2?: string | null;
  address2_not?: string | null;
  address2_in?: string[] | null;
  address2_not_in?: string[] | null;
  address2_lt?: string | null;
  address2_lte?: string | null;
  address2_gt?: string | null;
  address2_gte?: string | null;
  address2_contains?: string | null;
  address2_not_contains?: string | null;
  address2_starts_with?: string | null;
  address2_not_starts_with?: string | null;
  address2_ends_with?: string | null;
  address2_not_ends_with?: string | null;
  businessName?: string | null;
  businessName_not?: string | null;
  businessName_in?: string[] | null;
  businessName_not_in?: string[] | null;
  businessName_lt?: string | null;
  businessName_lte?: string | null;
  businessName_gt?: string | null;
  businessName_gte?: string | null;
  businessName_contains?: string | null;
  businessName_not_contains?: string | null;
  businessName_starts_with?: string | null;
  businessName_not_starts_with?: string | null;
  businessName_ends_with?: string | null;
  businessName_not_ends_with?: string | null;
  businessType?: BusinessType | null;
  businessType_not?: BusinessType | null;
  businessType_in?: BusinessType[] | null;
  businessType_not_in?: BusinessType[] | null;
  city?: string | null;
  city_not?: string | null;
  city_in?: string[] | null;
  city_not_in?: string[] | null;
  city_lt?: string | null;
  city_lte?: string | null;
  city_gt?: string | null;
  city_gte?: string | null;
  city_contains?: string | null;
  city_not_contains?: string | null;
  city_starts_with?: string | null;
  city_not_starts_with?: string | null;
  city_ends_with?: string | null;
  city_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  customerId?: string | null;
  customerId_not?: string | null;
  customerId_in?: string[] | null;
  customerId_not_in?: string[] | null;
  customerId_lt?: string | null;
  customerId_lte?: string | null;
  customerId_gt?: string | null;
  customerId_gte?: string | null;
  customerId_contains?: string | null;
  customerId_not_contains?: string | null;
  customerId_starts_with?: string | null;
  customerId_not_starts_with?: string | null;
  customerId_ends_with?: string | null;
  customerId_not_ends_with?: string | null;
  customerType?: CustomerType | null;
  customerType_not?: CustomerType | null;
  customerType_in?: CustomerType[] | null;
  customerType_not_in?: CustomerType[] | null;
  dateOfBirth?: any | null;
  dateOfBirth_not?: any | null;
  dateOfBirth_in?: any[] | null;
  dateOfBirth_not_in?: any[] | null;
  dateOfBirth_lt?: any | null;
  dateOfBirth_lte?: any | null;
  dateOfBirth_gt?: any | null;
  dateOfBirth_gte?: any | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: string[] | null;
  description_not_in?: string[] | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  firstName?: string | null;
  firstName_not?: string | null;
  firstName_in?: string[] | null;
  firstName_not_in?: string[] | null;
  firstName_lt?: string | null;
  firstName_lte?: string | null;
  firstName_gt?: string | null;
  firstName_gte?: string | null;
  firstName_contains?: string | null;
  firstName_not_contains?: string | null;
  firstName_starts_with?: string | null;
  firstName_not_starts_with?: string | null;
  firstName_ends_with?: string | null;
  firstName_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  lastName?: string | null;
  lastName_not?: string | null;
  lastName_in?: string[] | null;
  lastName_not_in?: string[] | null;
  lastName_lt?: string | null;
  lastName_lte?: string | null;
  lastName_gt?: string | null;
  lastName_gte?: string | null;
  lastName_contains?: string | null;
  lastName_not_contains?: string | null;
  lastName_starts_with?: string | null;
  lastName_not_starts_with?: string | null;
  lastName_ends_with?: string | null;
  lastName_not_ends_with?: string | null;
  password?: string | null;
  password_not?: string | null;
  password_in?: string[] | null;
  password_not_in?: string[] | null;
  password_lt?: string | null;
  password_lte?: string | null;
  password_gt?: string | null;
  password_gte?: string | null;
  password_contains?: string | null;
  password_not_contains?: string | null;
  password_starts_with?: string | null;
  password_not_starts_with?: string | null;
  password_ends_with?: string | null;
  password_not_ends_with?: string | null;
  plaidAccessToken?: string | null;
  plaidAccessToken_not?: string | null;
  plaidAccessToken_in?: string[] | null;
  plaidAccessToken_not_in?: string[] | null;
  plaidAccessToken_lt?: string | null;
  plaidAccessToken_lte?: string | null;
  plaidAccessToken_gt?: string | null;
  plaidAccessToken_gte?: string | null;
  plaidAccessToken_contains?: string | null;
  plaidAccessToken_not_contains?: string | null;
  plaidAccessToken_starts_with?: string | null;
  plaidAccessToken_not_starts_with?: string | null;
  plaidAccessToken_ends_with?: string | null;
  plaidAccessToken_not_ends_with?: string | null;
  postalCode?: string | null;
  postalCode_not?: string | null;
  postalCode_in?: string[] | null;
  postalCode_not_in?: string[] | null;
  postalCode_lt?: string | null;
  postalCode_lte?: string | null;
  postalCode_gt?: string | null;
  postalCode_gte?: string | null;
  postalCode_contains?: string | null;
  postalCode_not_contains?: string | null;
  postalCode_starts_with?: string | null;
  postalCode_not_starts_with?: string | null;
  postalCode_ends_with?: string | null;
  postalCode_not_ends_with?: string | null;
  profilePicture?: string | null;
  profilePicture_not?: string | null;
  profilePicture_in?: string[] | null;
  profilePicture_not_in?: string[] | null;
  profilePicture_lt?: string | null;
  profilePicture_lte?: string | null;
  profilePicture_gt?: string | null;
  profilePicture_gte?: string | null;
  profilePicture_contains?: string | null;
  profilePicture_not_contains?: string | null;
  profilePicture_starts_with?: string | null;
  profilePicture_not_starts_with?: string | null;
  profilePicture_ends_with?: string | null;
  profilePicture_not_ends_with?: string | null;
  state?: string | null;
  state_not?: string | null;
  state_in?: string[] | null;
  state_not_in?: string[] | null;
  state_lt?: string | null;
  state_lte?: string | null;
  state_gt?: string | null;
  state_gte?: string | null;
  state_contains?: string | null;
  state_not_contains?: string | null;
  state_starts_with?: string | null;
  state_not_starts_with?: string | null;
  state_ends_with?: string | null;
  state_not_ends_with?: string | null;
  timezone?: string | null;
  timezone_not?: string | null;
  timezone_in?: string[] | null;
  timezone_not_in?: string[] | null;
  timezone_lt?: string | null;
  timezone_lte?: string | null;
  timezone_gt?: string | null;
  timezone_gte?: string | null;
  timezone_contains?: string | null;
  timezone_not_contains?: string | null;
  timezone_starts_with?: string | null;
  timezone_not_starts_with?: string | null;
  timezone_ends_with?: string | null;
  timezone_not_ends_with?: string | null;
  token?: string | null;
  token_not?: string | null;
  token_in?: string[] | null;
  token_not_in?: string[] | null;
  token_lt?: string | null;
  token_lte?: string | null;
  token_gt?: string | null;
  token_gte?: string | null;
  token_contains?: string | null;
  token_not_contains?: string | null;
  token_starts_with?: string | null;
  token_not_starts_with?: string | null;
  token_ends_with?: string | null;
  token_not_ends_with?: string | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  website?: string | null;
  website_not?: string | null;
  website_in?: string[] | null;
  website_not_in?: string[] | null;
  website_lt?: string | null;
  website_lte?: string | null;
  website_gt?: string | null;
  website_gte?: string | null;
  website_contains?: string | null;
  website_not_contains?: string | null;
  website_starts_with?: string | null;
  website_not_starts_with?: string | null;
  website_ends_with?: string | null;
  website_not_ends_with?: string | null;
}

export interface UserUpdateDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateManyDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
}

export interface UserUpdateManyInput {
  create?: UserCreateInput[] | null;
  connect?: UserWhereUniqueInput[] | null;
  set?: UserWhereUniqueInput[] | null;
  disconnect?: UserWhereUniqueInput[] | null;
  delete?: UserWhereUniqueInput[] | null;
  update?: UserUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: UserUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: UserScalarWhereInput[] | null;
  upsert?: UserUpsertWithWhereUniqueNestedInput[] | null;
}

export interface UserUpdateManyWithWhereNestedInput {
  where: UserScalarWhereInput;
  data: UserUpdateManyDataInput;
}

export interface UserUpdateOneWithoutAccountsInput {
  create?: UserCreateWithoutAccountsInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutAccountsDataInput | null;
  upsert?: UserUpsertWithoutAccountsInput | null;
}

export interface UserUpdateOneWithoutFromsInput {
  create?: UserCreateWithoutFromsInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutFromsDataInput | null;
  upsert?: UserUpsertWithoutFromsInput | null;
}

export interface UserUpdateOneWithoutInvitesInput {
  create?: UserCreateWithoutInvitesInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutInvitesDataInput | null;
  upsert?: UserUpsertWithoutInvitesInput | null;
}

export interface UserUpdateOneWithoutMembersInput {
  create?: UserCreateWithoutMembersInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutMembersDataInput | null;
  upsert?: UserUpsertWithoutMembersInput | null;
}

export interface UserUpdateOneWithoutPendingInvitesInput {
  create?: UserCreateWithoutPendingInvitesInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutPendingInvitesDataInput | null;
  upsert?: UserUpsertWithoutPendingInvitesInput | null;
}

export interface UserUpdateOneWithoutPermissionsInput {
  create?: UserCreateWithoutPermissionsInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutPermissionsDataInput | null;
  upsert?: UserUpsertWithoutPermissionsInput | null;
}

export interface UserUpdateOneWithoutRequestsCreatedInput {
  create?: UserCreateWithoutRequestsCreatedInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutRequestsCreatedDataInput | null;
  upsert?: UserUpsertWithoutRequestsCreatedInput | null;
}

export interface UserUpdateOneWithoutRequestsInput {
  create?: UserCreateWithoutRequestsInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutRequestsDataInput | null;
  upsert?: UserUpsertWithoutRequestsInput | null;
}

export interface UserUpdateOneWithoutSelectedAccountInput {
  create?: UserCreateWithoutSelectedAccountInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutSelectedAccountDataInput | null;
  upsert?: UserUpsertWithoutSelectedAccountInput | null;
}

export interface UserUpdateOneWithoutSendsCreatedInput {
  create?: UserCreateWithoutSendsCreatedInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutSendsCreatedDataInput | null;
  upsert?: UserUpsertWithoutSendsCreatedInput | null;
}

export interface UserUpdateOneWithoutSendsInput {
  create?: UserCreateWithoutSendsInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutSendsDataInput | null;
  upsert?: UserUpsertWithoutSendsInput | null;
}

export interface UserUpdateOneWithoutTosInput {
  create?: UserCreateWithoutTosInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateWithoutTosDataInput | null;
  upsert?: UserUpsertWithoutTosInput | null;
}

export interface UserUpdateWithWhereUniqueNestedInput {
  where: UserWhereUniqueInput;
  data: UserUpdateDataInput;
}

export interface UserUpdateWithoutAccountsDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutFromsDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutInvitesDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutMembersDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutPendingInvitesDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutPermissionsDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutRequestsCreatedDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutRequestsDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutSelectedAccountDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutSendsCreatedDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutSendsDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
  tos?: SendUpdateManyWithoutToUserInput | null;
}

export interface UserUpdateWithoutTosDataInput {
  address?: string | null;
  address2?: string | null;
  businessName?: string | null;
  businessType?: BusinessType | null;
  city?: string | null;
  customerId?: string | null;
  customerType?: CustomerType | null;
  dateOfBirth?: any | null;
  description?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  plaidAccessToken?: string | null;
  postalCode?: string | null;
  profilePicture?: string | null;
  signupData?: any | null;
  state?: string | null;
  timezone?: string | null;
  token?: string | null;
  verificationData?: any | null;
  website?: string | null;
  customerStatus?: UserUpdatecustomerStatusInput | null;
  ips?: UserUpdateipsInput | null;
  accounts?: AccountUpdateManyWithoutUserInput | null;
  contacts?: UserUpdateManyInput | null;
  froms?: RequestUpdateManyWithoutFromUserInput | null;
  invites?: InviteUpdateManyWithoutUserInput | null;
  members?: PermissionUpdateManyWithoutBusinessInput | null;
  pendingInvites?: InviteUpdateManyWithoutForUserInput | null;
  permissions?: PermissionUpdateManyWithoutUserInput | null;
  requests?: RequestUpdateManyWithoutUserInput | null;
  requestsCreated?: RequestUpdateManyWithoutCreatedByInput | null;
  selectedAccount?: AccountUpdateOneWithoutSelectedInput | null;
  sends?: SendUpdateManyWithoutUserInput | null;
  sendsCreated?: SendUpdateManyWithoutCreatedByInput | null;
}

export interface UserUpdatecustomerStatusInput {
  set?: CustomerStatus[] | null;
}

export interface UserUpdateipsInput {
  set?: string[] | null;
}

export interface UserUpsertWithWhereUniqueNestedInput {
  where: UserWhereUniqueInput;
  update: UserUpdateDataInput;
  create: UserCreateInput;
}

export interface UserUpsertWithoutAccountsInput {
  update: UserUpdateWithoutAccountsDataInput;
  create: UserCreateWithoutAccountsInput;
}

export interface UserUpsertWithoutFromsInput {
  update: UserUpdateWithoutFromsDataInput;
  create: UserCreateWithoutFromsInput;
}

export interface UserUpsertWithoutInvitesInput {
  update: UserUpdateWithoutInvitesDataInput;
  create: UserCreateWithoutInvitesInput;
}

export interface UserUpsertWithoutMembersInput {
  update: UserUpdateWithoutMembersDataInput;
  create: UserCreateWithoutMembersInput;
}

export interface UserUpsertWithoutPendingInvitesInput {
  update: UserUpdateWithoutPendingInvitesDataInput;
  create: UserCreateWithoutPendingInvitesInput;
}

export interface UserUpsertWithoutPermissionsInput {
  update: UserUpdateWithoutPermissionsDataInput;
  create: UserCreateWithoutPermissionsInput;
}

export interface UserUpsertWithoutRequestsCreatedInput {
  update: UserUpdateWithoutRequestsCreatedDataInput;
  create: UserCreateWithoutRequestsCreatedInput;
}

export interface UserUpsertWithoutRequestsInput {
  update: UserUpdateWithoutRequestsDataInput;
  create: UserCreateWithoutRequestsInput;
}

export interface UserUpsertWithoutSelectedAccountInput {
  update: UserUpdateWithoutSelectedAccountDataInput;
  create: UserCreateWithoutSelectedAccountInput;
}

export interface UserUpsertWithoutSendsCreatedInput {
  update: UserUpdateWithoutSendsCreatedDataInput;
  create: UserCreateWithoutSendsCreatedInput;
}

export interface UserUpsertWithoutSendsInput {
  update: UserUpdateWithoutSendsDataInput;
  create: UserCreateWithoutSendsInput;
}

export interface UserUpsertWithoutTosInput {
  update: UserUpdateWithoutTosDataInput;
  create: UserCreateWithoutTosInput;
}

export interface UserWhereUniqueInput {
  customerId?: string | null;
  email?: string | null;
  id?: string | null;
  token?: string | null;
}

export interface ValidateEmailInput {
  token: string;
}

export interface VerifyBeneficialOwnerInput {
  customerId?: string | null;
  documentType?: string | null;
  file?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  ssn?: string | null;
  dateOfBirth?: string | null;
  address?: VerifyControllerAddressInput | null;
  passport?: VerifyControllerPassportInput | null;
  title?: string | null;
}

export interface VerifyControllerAddressInput {
  address1: string;
  address2?: string | null;
  city: string;
  stateProvinceRegion: string;
  country: string;
  postalCode?: string | null;
}

export interface VerifyControllerInput {
  firstName: string;
  lastName: string;
  title: string;
  dateOfBirth: any;
  address?: VerifyControllerAddressInput | null;
  ssn?: string | null;
  passport?: VerifyControllerPassportInput | null;
}

export interface VerifyControllerPassportInput {
  number: string;
  country: string;
}

export interface VerifyCustomerInput {
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  type: CustomerType;
  dateOfBirth?: string | null;
  ssn?: string | null;
  ein?: string | null;
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  postalCode?: string | null;
  businessName?: string | null;
  businessType?: string | null;
  industryClassification?: string | null;
  businessClassification?: string | null;
  controller?: VerifyControllerInput | null;
  correlationId?: string | null;
  beneficialOwners?: VerifyBeneficialOwnerInput[] | null;
  certify?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
