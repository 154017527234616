import React from "react";
const HomeScreen = React.lazy(() => import("./HomeScreen"));
const RegisterScreen = React.lazy(() => import("./RegisterScreen"));
const ProfileScreen = React.lazy(() => import("./ProfileScreen"));
const ProfileBusinessScreen = React.lazy(() =>
  import("./ProfileBusinessScreen")
);
const AccountsScreen = React.lazy(() => import("./AccountsScreen"));
const RequestScreen = React.lazy(() => import("./RequestScreen"));
const SendScreen = React.lazy(() => import("./SendScreen"));
const ActivityScreen = React.lazy(() => import("./ActivityScreen"));
const RequestDetailScreen = React.lazy(() => import("./RequestDetailScreen"));
const LoginScreen = React.lazy(() => import("./LoginScreen"));
const UpdatePasswordScreen = React.lazy(() => import("./UpdatePasswordScreen"));
const ContactsScreen = React.lazy(() => import("./ContactsScreen"));
const SettingsScreen = React.lazy(() => import("./SettingsScreen"));
const HelpScreen = React.lazy(() => import("./HelpScreen"));
const SendDetailScreen = React.lazy(() =>
  import("../components/SendDetailScreen")
);
const ContactScreen = React.lazy(() => import("./ContactScreen"));
const AddContactScreen = React.lazy(() => import("./AddContactScreen"));
const VerifyBusinessEntity = React.lazy(() =>
  import("./verify/VerifyBusinessEntity")
);
const VerifyPersonal = React.lazy(() => import("./verify/VerifyPersonal"));
const Verify = React.lazy(() => import("./verify/Verify"));
const ProfileEditScreen = React.lazy(() => import("./ProfileEditScreen"));
const ValidateEmailScreen = React.lazy(() => import("./ValidateEmailScreen"));
const UnsupportedScreen = React.lazy(() => import("./UnsupportedScreen"));
const InviteContactScreen = React.lazy(() => import("./InviteContactScreen"));
const InviteMemberScreen = React.lazy(() => import("./InviteMemberScreen"));
const ProfileBusinessEditScreen = React.lazy(() =>
  import("./ProfileBusinessEditScreen")
);
const AccountAdministrationScreen = React.lazy(() =>
  import("./AccountAdministrationScreen")
);
const ResetPasswordScreen = React.lazy(() => import("./ResetPasswordScreen"));
const NotFound = React.lazy(() => import("./NotFound"));
const SignupSuccessScreen = React.lazy(() => import("./SignupSuccessScreen"));
const SendScheduleScreen = React.lazy(() => import("./SendScheduleScreen"));
import { Switch, Route } from "react-router-dom";
import colors from "../lib/colors";
import { ActivityIndicator } from "react-native";
import Container from "../components/Container";

export default function Router(props) {
  return (
    <React.Suspense
      fallback={
        <Container>
          <ActivityIndicator color={colors.blue} size="large" />
        </Container>
      }
    >
      <Switch>
        <Route exact component={HomeScreen} path="/" />
        <Route exact component={RegisterScreen} path="/register" />
        <Route exact component={ProfileScreen} path="/profile" />
        <Route exact component={ProfileEditScreen} path="/profile/edit" />
        <Route
          exact
          component={ProfileBusinessScreen}
          path="/profile/business"
        />
        <Route
          exact
          component={ProfileBusinessEditScreen}
          path="/profile/business/edit"
        />
        <Route exact component={AccountsScreen} path="/accounts" />
        <Route exact component={RequestScreen} path="/request" />
        <Route exact component={RequestDetailScreen} path="/request/:id" />
        <Route
          exact
          component={SignupSuccessScreen}
          path="/request/:id/success"
        />
        <Route exact component={SendDetailScreen} path="/send/:id" />
        <Route exact component={SendScreen} path="/send/:id/edit" />
        <Route
          exact
          component={SendScheduleScreen}
          path="/send/:id/edit/schedule"
        />
        <Route exact component={SignupSuccessScreen} path="/send/:id/success" />
        <Route exact component={ActivityScreen} path="/activity" />
        <Route exact component={SendScreen} path="/send" />
        <Route exact component={LoginScreen} path="/login" />
        <Route exact component={ResetPasswordScreen} path="/reset-password" />
        <Route exact component={UpdatePasswordScreen} path="/update-password" />
        <Route exact component={ValidateEmailScreen} path="/validate-email" />
        <Route exact component={ContactsScreen} path="/contacts" />
        <Route exact component={SettingsScreen} path="/settings" />
        <Route exact component={HelpScreen} path="/help" />
        <Route exact component={AddContactScreen} path="/contacts/add" />
        <Route exact component={InviteContactScreen} path="/contacts/invite" />
        <Route exact component={ContactScreen} path="/contacts/:id" />
        <Route exact component={Verify} path="/verify" />
        <Route
          exact
          component={VerifyBusinessEntity}
          path="/verify/business-entity/:step?/:number?"
        />
        <Route
          exact
          component={VerifyPersonal}
          path="/verify/personal/:step?"
        />
        <Route exact component={UnsupportedScreen} path="/unsupported" />
        <Route exact component={InviteMemberScreen} path="/members/invite" />
        <Route exact component={InviteMemberScreen} path="/members/:id" />
        <Route
          exact
          component={AccountAdministrationScreen}
          path="/account-administration"
        />
        <Route component={NotFound} />
      </Switch>
    </React.Suspense>
  );
}
