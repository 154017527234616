import React, { useState, useEffect, ReactNode } from "react";
import { client } from "./Apollo";
import {
  MeQuery,
  ValidateTokenMutation,
  UpdateUserMutation,
  CheckBalanceQuery,
} from "../api/User";
import {
  me,
  me_me,
  PermissionType,
  validateToken,
  CustomerType,
  updateUser,
  me_me_business,
  checkBalance,
} from "../typings/client";
import Storage from "./Storage";
import { useLocation, useHistory } from "react-router-dom";
import titles from "../lib/titles";

interface Props {
  children?: ReactNode;
}

interface Context {
  user: me_me;
  business: me_me;
  ready: boolean;
  menuOpen: boolean;
  setMenuOpen?: (open: boolean) => void;
  setUser?: (user: me_me) => void;
  setBusiness?: (user: me_me_business) => void;
  getCurrentUser?: () => Promise<me_me>;
  getCurrentBusinessPermissions?: () => PermissionType[];
  signOut?: () => void;
  title: string;
  setTitle?: (title) => void;
  toast?: any;
  setToast?: (data: {
    type?: "success" | "error";
    title: string;
    description: string;
  }) => void;
}

const initialStore = {
  user: null,
  business: null,
  ready: false,
  menuOpen: false,
  dimensions: null,
  title: "Checkless",
};

export const StoreContext = React.createContext<Context>(initialStore);

const dontRedirect = [
  /\/register/,
  /\/request\/[a-z0-9]+/,
  /\/request\/[a-z0-9]+\/success/,
  /\/send\/[a-z0-9]+/,
  /\/send\/[a-z0-9]+\/success/,
  /\/login/,
  /\/update-password/,
  /\/validate-email/,
  /\/unsupported/,
  /\/accept-invite/,
  /\/reset-password/,
];

let interval;

export function StoreProvider(props: Props) {
  const [user, setUser] = useState<me_me>(null);
  const [business, setBusiness] = useState(null);
  const [ready, setReady] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [toast, setToast] = useState(null);
  const [title, setTitle] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  async function checkCurrentUser() {
    const token = await Storage.getSessionItem("token");

    if (Storage.getItem("token")) {
      Storage.removeItem("token");
    }

    const inviteToken = searchParams.get("token");
    let user;

    if (token) {
      user = await getCurrentUser();
    }

    if (
      inviteToken &&
      location.pathname !== "/update-password" &&
      location.pathname !== "/validate-email"
    ) {
      validateToken(inviteToken);
    }

    if (user) {
      if (
        user.customerType === CustomerType.Personal ||
        user.customerType === CustomerType.Business
      ) {
        checkUserBalance();
      }

      // if (
      //   user.customerType === CustomerType.Unverified &&
      //   location.pathname !== "/validate-email" &&
      //   !dontRedirect.some(regex => regex.test(location.pathname))
      // ) {
      //   history.push("/verify");
      // }
    } else if (!dontRedirect.some((regex) => regex.test(location.pathname))) {
      // console.log("activePath", navigation);
      searchParams.set("next", location.pathname);

      history.push(`/login?${searchParams.toString()}`);
    }

    setReady(true);
  }

  async function validateToken(token) {
    try {
      const validatedToken = await client.mutate<validateToken>({
        mutation: ValidateTokenMutation,
        variables: {
          token,
        },
      });

      if (validatedToken.data.validateToken.success && user) {
        await getCurrentUser();
        history.push("/activity");
      }
    } catch (e) {
      console.log("e", e);
      history.push(location.pathname);
      setToast({
        title: "Error",
        type: "error",
        description: e.message,
      });
    }
  }

  function getCurrentBusinessPermissions() {
    let permission = user.permissions.find(
      (p) => business && p.business.id === business.id
    );

    if (!business && user.customerType === CustomerType.Business) {
      permission = {
        __typename: "Permission",
        id: "owner",
        business: null,
        permissions: [PermissionType.Admin],
      };
    }

    return permission ? permission.permissions : [];
  }

  async function getCurrentUser() {
    try {
      const user = await client
        .query<me>({
          query: MeQuery,
          fetchPolicy: "no-cache",
        })
        .then((res) => res.data.me);

      if (user) {
        console.log("getCurrentUser user", user);
        // console.log(Intl.DateTimeFormat().resolvedOptions());
        setUser(user);
        Storage.setSessionItem("token", user.token);

        if (!user.timezone) {
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          client.mutate<updateUser>({
            mutation: UpdateUserMutation,
            variables: {
              data: {
                timezone,
              },
              where: {
                id: user.id,
              },
            },
          });
        }
      }

      if (user.business) {
        setBusiness(user.business);
      }

      return user;
    } catch (e) {
      Storage.removeSessionItem("token");
      history.push(
        `/login?next=${location.pathname}${location.search}&loggedOut=true`
      );
      setUser(null);
      console.log("e", e);
    }
  }

  async function checkUserBalance() {
    try {
      const result = await client.query<checkBalance>({
        query: CheckBalanceQuery,
      });

      setUser((user) => ({
        ...user,
        balance: result.data.checkBalance,
      }));
    } catch (e) {
      console.log("e", e);
    }
  }

  async function signOut() {
    setUser(null);
    setBusiness(null);
    Storage.removeSessionItem("token");
  }

  useEffect(() => {
    Object.keys(titles).forEach((route) => {
      const isCurrent = location.pathname.startsWith(route);

      if (isCurrent) {
        setTitle(titles[route]);
      }
    });

    if (user && process.env.REACT_APP_ENV === "production") {
      if (location.pathname.includes("/verify")) {
        (window as any).Intercom("boot", {
          app_id: "ww9essur",
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          hide_default_launcher: false,
        });
      } else {
        (window as any).Intercom("update", {
          hide_default_launcher: true,
        });
        (window as any).Intercom("hide");
      }
    }
  }, [location]);

  useEffect(() => {
    checkCurrentUser();

    window.addEventListener("focus", () => {
      if (user) {
        interval = setInterval(getCurrentUser, 10 * 60 * 1000);
      }
    });

    window.addEventListener("blur", () => {
      clearInterval(interval);
    });
  }, []);

  return (
    <StoreContext.Provider
      value={{
        business,
        getCurrentUser,
        getCurrentBusinessPermissions,
        menuOpen,
        ready,
        setBusiness,
        setMenuOpen,
        setToast,
        setUser,
        signOut,
        title,
        setTitle,
        toast,
        user,
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
}
