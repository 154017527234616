class Storage {
  getItem(key: string) {
    return localStorage.getItem(key);
  }
  getSessionItem(key: string) {
    return sessionStorage.getItem(key);
  }
  setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }
  setSessionItem(key: string, value: string) {
    return sessionStorage.setItem(key, value);
  }
  removeItem(key: string) {
    return localStorage.removeItem(key);
  }
  removeSessionItem(key: string) {
    return sessionStorage.removeItem(key);
  }
}

export default new Storage();
