import React from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";

export default function Container(props) {
  const { width } = useWindowDimensions();

  return (
    <View>
      <View
        style={[
          styles.container,
          width > 800 && styles.wide,
          width > 1000 && styles.wider,
          props.style,
        ]}
      >
        {props.children}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
    flex: 1,
  },
  wide: {
    minWidth: 800,
    maxWidth: 800,
    alignSelf: "center",
  },
  wider: {
    minWidth: 1000,
    maxWidth: 1000,
    alignSelf: "center",
  },
});
