import getTitle from "./getTitle";

export default {
  "/": getTitle("Home"),
  "/register": getTitle("Register"),
  "/profile": getTitle("Profile"),
  "/profile/edit": getTitle("Edit Profile"),
  "/profile/business": getTitle("Business Profile"),
  "/profile/business/edit": getTitle("Edit Business Profile"),
  "/accounts": getTitle("Accounts"),
  "/request": getTitle("Request"),
  "/request/": getTitle("Request Detail"),
  "/send/": getTitle("Send"),
  "/activity": getTitle("Activity"),
  "/send": getTitle("Send"),
  "/terms-of-service": getTitle("Terms of Service"),
  "/privacy-policy": getTitle("Privacy Policy"),
  "/login": getTitle("Login"),
  "/reset-password": getTitle("Reset Password"),
  "/update-password": getTitle("Update Password"),
  "/validate-email": getTitle("Validate"),
  "/contacts": getTitle("Contacts"),
  "/settings": getTitle("Settings"),
  "/help": getTitle("Help"),
  "/contacts/add": getTitle("Add Contact"),
  "/contacts/invite": getTitle("Invoice Contact"),
  "/contacts/": getTitle("Contact Detail"),
  "/verify": getTitle("Verify"),
  "/verify/business-entity": getTitle("Verify Business Account"),
  "/verify/personal": getTitle("Verify Personal Account"),
  "/unsupported": getTitle("Unsupported"),
  "/members/invite": getTitle("Invite Member"),
  "/members/": getTitle("Member Detail"),
  "/account-administration": getTitle("Account Administration")
};
