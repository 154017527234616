import React, { useContext, useState } from "react";
import { View, TouchableOpacity, StyleSheet, Image } from "react-native";
import Sans from "./Sans";
import colors from "../lib/colors";
import { StoreContext } from "../stores/Store";
import * as Animatable from "react-native-animatable";
import Container from "./Container";
import { CustomerType } from "../typings/client";
import BusinessInfo from "./BusinessInfo";
import Avatar from "./Avatar";
import Row from "./Row";
import Touchable from "./Touchable";
import Storage from "../stores/Storage";
import { useHistory } from "react-router-dom";

export default function Menu(props) {
  const history = useHistory();
  const store = useContext(StoreContext);
  const [switching, setSwitching] = useState(false);
  const logins = JSON.parse(Storage.getItem("logins") || "[]");

  if (!store.user) {
    return null;
  }

  function selectMembership(login) {
    setSwitching(false);
    store.setMenuOpen(false);

    if (login.id === store.user.id) {
      return;
    }

    store.signOut();
    history.push(`/login?email=${login.email}`);
  }

  let content = (
    <React.Fragment>
      <React.Fragment>
        <View style={styles.menuRow}>
          <TouchableOpacity
            style={styles.menuTextContainer}
            onPress={() => {
              history.push("/activity");
              store.setMenuOpen(false);
            }}
          >
            <Sans style={styles.menuText}>Activity</Sans>
          </TouchableOpacity>
        </View>

        <View style={styles.menuRow}>
          <TouchableOpacity
            style={styles.menuTextContainer}
            onPress={() => {
              history.push("/contacts");
              store.setMenuOpen(false);
            }}
          >
            <Sans style={styles.menuText}>Connections</Sans>
          </TouchableOpacity>
        </View>
      </React.Fragment>

      {store.user && store.user.customerType === CustomerType.Personal && (
        <View style={styles.menuRow}>
          <TouchableOpacity
            style={styles.menuTextContainer}
            onPress={() => {
              history.push("/profile");
              store.setMenuOpen(false);
            }}
          >
            <Sans style={styles.menuText}>My Account</Sans>
          </TouchableOpacity>
        </View>
      )}

      {store.user &&
        (store.user.customerType === CustomerType.Business ||
          store.business) && (
          <View style={styles.menuRow}>
            <TouchableOpacity
              style={styles.menuTextContainer}
              onPress={() => {
                history.push("/profile");
                store.setMenuOpen(false);
              }}
            >
              <Sans style={styles.menuText}>Account & Profile</Sans>
            </TouchableOpacity>
          </View>
        )}

      <View style={styles.menuRow}>
        <TouchableOpacity
          style={styles.menuTextContainer}
          onPress={() => {
            window.open("https://intercom.help/checkless");
            store.setMenuOpen(false);
          }}
        >
          <Sans style={styles.menuText}>Help</Sans>
        </TouchableOpacity>
      </View>

      <View style={styles.menuRow}>
        <TouchableOpacity
          style={styles.menuTextContainer}
          onPress={() => {
            store.signOut();
            store.setMenuOpen(false);
            history.push("/login");
          }}
        >
          <Sans style={styles.menuText}>Sign Out</Sans>
        </TouchableOpacity>
      </View>
    </React.Fragment>
  );

  if (
    store.user.customerType === CustomerType.Unverified &&
    store.user.permissions.length === 0 &&
    !store.business
  ) {
    content = (
      <React.Fragment>
        <View style={styles.menuRow}>
          <TouchableOpacity
            style={styles.menuTextContainer}
            onPress={() => {
              history.push("/verify");
              store.setMenuOpen(false);
            }}
          >
            <Sans style={styles.menuText}>Verify Account</Sans>
          </TouchableOpacity>
        </View>

        <View style={styles.menuRow}>
          <TouchableOpacity
            style={styles.menuTextContainer}
            onPress={() => {
              history.push("/activity");
              store.setMenuOpen(false);
            }}
          >
            <Sans style={styles.menuText}>Activity</Sans>
          </TouchableOpacity>
        </View>

        <View style={styles.menuRow}>
          <TouchableOpacity
            style={styles.menuTextContainer}
            onPress={() => {
              history.push("/contacts");
              store.setMenuOpen(false);
            }}
          >
            <Sans style={styles.menuText}>Connections</Sans>
          </TouchableOpacity>
        </View>

        <View style={styles.menuRow}>
          <TouchableOpacity
            style={styles.menuTextContainer}
            onPress={() => {
              history.push("/profile");
              store.setMenuOpen(false);
            }}
          >
            <Sans style={styles.menuText}>My Account</Sans>
          </TouchableOpacity>
        </View>
        <View style={styles.menuRow}>
          <TouchableOpacity
            style={styles.menuTextContainer}
            onPress={() => {
              window.open("https://intercom.help/checkless");
              store.setMenuOpen(false);
            }}
          >
            <Sans style={styles.menuText}>Help</Sans>
          </TouchableOpacity>
        </View>
        <View style={styles.menuRow}>
          <TouchableOpacity
            style={styles.menuTextContainer}
            onPress={() => {
              store.signOut();
              store.setMenuOpen(false);
              history.push("/login");
            }}
          >
            <Sans style={styles.menuText}>Sign Out</Sans>
          </TouchableOpacity>
        </View>
      </React.Fragment>
    );
  }

  if (switching) {
    content = (
      <React.Fragment>
        {logins.map((l, i) => {
          let name = `${l.firstName} ${l.lastName}`;
          let meta = l.email;

          if (l.businessName) {
            name = l.businessName;
          }

          if (!l.firstName) {
            name = l.email;
          }
          return (
            <View style={styles.menuRow} key={i}>
              <TouchableOpacity
                style={styles.menuTextContainer}
                onPress={() => selectMembership(l)}
              >
                <Row>
                  <Avatar user={l} style={{ marginRight: 15 }} />
                  <View style={{ marginTop: 2, flex: 1 }}>
                    <Sans style={styles.menuText}>{name}</Sans>
                    <Sans style={styles.meta}>{meta}</Sans>
                  </View>
                  {l.id === store.user.id && (
                    <View style={styles.selectedContainer}>
                      <Image
                        source={require("../img/checkmark-green.svg")}
                        style={styles.selectedIcon}
                      />
                    </View>
                  )}
                </Row>
              </TouchableOpacity>
            </View>
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <Animatable.View
      transition={["opacity", "scaleX", "scaleY"]}
      duration={150}
      pointerEvents={store.menuOpen ? "auto" : "none"}
      style={[
        styles.container,
        {
          opacity: store.menuOpen ? 1 : 0,
          transform: [
            {
              scaleX: store.menuOpen ? 1 : 0.9
            },
            {
              scaleY: store.menuOpen ? 1 : 0.9
            }
          ],
          transformOrigin: "top right"
        } as any
      ]}
    >
      <Container>
        {store.user && (
          <View style={{ marginBottom: 10 }}>
            <Row alignCenter>
              <Avatar user={store.business ? store.business : store.user} />
              <BusinessInfo
                style={{ alignItems: "flex-start", marginLeft: 10 }}
              />
            </Row>
          </View>
        )}
        <View style={{ marginBottom: 15 }}>
          {switching ? (
            <Sans style={{ fontSize: 12, color: colors.grayLight }}>
              <Touchable onPress={() => setSwitching(false)}>
                <Sans style={{ color: colors.blue }}>Menu</Sans>
              </Touchable>{" "}
              / Switch Account
            </Sans>
          ) : (
            <Sans style={{ fontSize: 12, color: colors.grayLight }}>Menu</Sans>
          )}
        </View>
        {content}
        {logins.length > 1 && !switching && (
          <View style={styles.menuRow}>
            <TouchableOpacity
              style={styles.menuTextContainer}
              onPress={() => {
                setSwitching(true);
              }}
            >
              <Row>
                <Sans style={[styles.menuText, { flex: 1 }]}>
                  Switch Account
                </Sans>
                <Image
                  source={require("../img/arrow-right.svg")}
                  style={styles.icon}
                />
              </Row>
            </TouchableOpacity>
          </View>
        )}
        <View style={{ marginBottom: 15, marginTop: 15 }}>
          <Sans style={{ fontSize: 12, color: colors.grayLight }}>
            <Touchable
              onPress={() => {
                window.open("https://checkless.com/privacy-policy");
                store.setMenuOpen(false);
              }}
            >
              <Sans style={{ color: colors.blue }}>Privacy Policy</Sans>
            </Touchable>{" "}
            <Touchable
              onPress={() => {
                window.open("https://checkless.com/terms-of-service");
                store.setMenuOpen(false);
              }}
            >
              <Sans style={{ color: colors.blue, marginLeft: 10 }}>
                Terms of Service
              </Sans>
            </Touchable>
          </Sans>
        </View>
      </Container>
    </Animatable.View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    left: 0,
    top: 60,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: "#fff"
  },
  menuRow: {
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: colors.blueLighter,
    marginBottom: -1,
    paddingVertical: 5
  },
  menuTextContainer: {
    paddingHorizontal: 20,
    height: 50,
    justifyContent: "center"
  },
  menuText: {
    color: colors.grayLight,
    fontWeight: "600",
    fontSize: 20
  },
  icon: {
    width: 15,
    height: 15,
    resizeMode: "contain",
    tintColor: colors.grayLight,
    marginTop: 4
  },
  meta: { color: colors.grayLighter, fontSize: 13 },
  selectedContainer: {
    width: 40,
    height: 40,
    justifyContent: "center"
  },
  selectedIcon: {
    width: 25,
    height: 25,
    resizeMode: "contain",
    tintColor: colors.green
  }
});
