import React from "react";
import { View, StyleSheet } from "react-native";
import { hot } from "react-hot-loader/root";
import colors from "./lib/colors";
import { StoreProvider } from "./stores/Store";
import Main from "./screens/Main";
import { BrowserRouter } from "react-router-dom";

function App(props) {
  return (
    <BrowserRouter>
      <StoreProvider {...props}>
        <View style={styles.container}>
          <Main {...props} />
        </View>
      </StoreProvider>
    </BrowserRouter>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.blueLightest
  }
});

export default hot(App);
