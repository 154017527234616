import React from "react";
import { View, StyleSheet, Image } from "react-native";
import Sans from "./Sans";
import colors from "../lib/colors";
import {
  me_me,
  CustomerType,
  me_me_contacts,
  request_request_user,
  send_send_toUser,
} from "../typings/client";

interface Props {
  user: request_request_user | me_me | send_send_toUser | me_me_contacts | any;
  userName?: string;
  style?: any;
}

export default function Avatar(props: Props) {
  let content;

  if (props.user && props.user.profilePicture) {
    let src = props.user.profilePicture;

    if (src.includes("checkless.com")) {
      src = `https://ik.imagekit.io/7bhakrpo9q/${props.user.profilePicture
        .replace("https://api-dev.checkless.com", "")
        .replace("https://api.checkless.com", "")}?tr=w-100&f=auto&rt=auto`;
    }

    content = (
      <Image
        source={{ uri: src }}
        style={styles.avatar}
        key={props.user.profilePicture}
      />
    );
  } else if (props.user && props.user.firstName) {
    content = (
      <Sans style={styles.avatarText}>
        {props.user.firstName.substr(0, 1).toUpperCase()}
        {props.user.lastName.substr(0, 1).toUpperCase()}
      </Sans>
    );
  } else if (props.userName) {
    content = (
      <Sans style={styles.avatarText}>
        {props.userName.substr(0, 1).toUpperCase()}
      </Sans>
    );
  }

  return (
    <View
      style={[
        styles.container,
        props.user &&
          props.user.customerType === CustomerType.Business &&
          styles.businessContainer,
        props.style,
      ]}
    >
      {content}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 40,
    height: 40,
    borderRadius: 6,
    overflow: "hidden",
    backgroundColor: colors.grayLightest,
    alignItems: "center",
    justifyContent: "center",
  },
  businessContainer: {
    borderRadius: 6,
  },
  avatar: {
    width: 40,
    height: 40,
    resizeMode: "cover",
  },
  avatarText: {
    color: colors.blueDarkest,
    fontSize: 18,
  },
});
