import React, { useContext, useEffect, useState, useRef } from "react";
import { Text, StyleSheet, View, Image, TouchableOpacity } from "react-native";
import { StoreContext } from "../stores/Store";
import * as Animatable from "react-native-animatable";
import colors from "../lib/colors";

export default function Toast(props) {
  const store = useContext(StoreContext);
  const [visible, setVisible] = useState(false);
  let timeout = useRef(null);

  useEffect(() => {
    if (store.toast) {
      setVisible(true);

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        setVisible(false);
        setTimeout(() => {
          store.setToast(null);
        }, 250);
      }, 4 * 1000);
    }
  }, [store.toast]);

  function handleClose() {
    setVisible(false);
  }

  return (
    <Animatable.View
      transition={["opacity", "translateY"]}
      duration={250}
      style={[
        styles.toast,
        {
          opacity: visible ? 1 : 0,
          transform: [
            {
              translateY: visible ? 0 : -100,
            },
          ],
        } as any,
      ]}
    >
      <View style={styles.header}>
        <Text
          style={[
            styles.heading,
            store.toast && store.toast.type === "error" && styles.headingError,
          ]}
        >
          {store.toast && store.toast.title}
        </Text>
        <TouchableOpacity onPress={handleClose}>
          <Image source={require("../img/close.svg")} style={styles.close} />
        </TouchableOpacity>
      </View>
      <View style={styles.body}>
        <Text style={styles.text}>
          {store.toast &&
            store.toast.description &&
            store.toast.description.replace("GraphQL error: ", "")}
        </Text>
      </View>
    </Animatable.View>
  );
}

const styles = StyleSheet.create({
  toast: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0,
    margin: "auto",
    top: 20,
    width: 330,
    backgroundColor: "#fff",
    borderRadius: 6,
    shadowRadius: 30,
    shadowOpacity: 0.15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 10,
    },
  },
  header: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderColor: colors.blueLightest,
    flexDirection: "row",
    alignItems: "center",
  },
  heading: {
    fontWeight: "600",
    fontSize: 13,
    color: colors.green,
    flex: 1,
  },
  headingError: {
    color: colors.red,
  },
  body: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    paddingBottom: 20,
  },
  text: {
    color: colors.blueDarkest,
    fontSize: 13,
  },
  close: {
    width: 18,
    height: 18,
    resizeMode: "contain",
    tintColor: colors.grayLighter,
  },
});
