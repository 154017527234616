import gql from "graphql-tag";
import { Send } from "../../server/generated/prisma";

export const SendPayload = gql`
  fragment SendPayload on Send {
    id
    account {
      id
      name
      mask
      institution
    }
    amount
    canCancel
    completedAt
    createdAt
    createdBy {
      id
    }
    for
    paymentDate
    processedAt
    to
    toUser {
      id
      businessName
      firstName
      lastName
      email
      profilePicture
    }
    request {
      id
      fromPaysFee
      status
      user {
        id
        businessName
        firstName
        lastName
      }
    }
    status
    updatedAt
    user {
      id
      businessName
      firstName
      lastName
      email
      profilePicture
    }
  }
`;

export const CreateSendMutation = gql`
  mutation createSend($data: SendCreateInput!) {
    createSend(data: $data) {
      ...SendPayload
    }
  }
  ${SendPayload}
`;

export const UpdateSendMutation = gql`
  mutation updateSend($data: SendUpdateInput!, $where: SendWhereUniqueInput!) {
    updateSend(data: $data, where: $where) {
      ...SendPayload
    }
  }
  ${SendPayload}
`;

export const SendQuery = gql`
  query send($where: SendWhereUniqueInput!) {
    send(where: $where) {
      ...SendPayload
    }
  }
  ${SendPayload}
`;

export function isSend(obj): obj is Send {
  return !!obj.to || obj.for === "Balance Transfer" || !!obj.toUser;
}

export const AcceptPaymentMutation = gql`
  mutation acceptPayment($id: ID!) {
    acceptPayment(id: $id) {
      ...SendPayload
    }
  }
  ${SendPayload}
`;

export const SendPaymentMutation = gql`
  mutation sendPayment($where: SendWhereUniqueInput!) {
    sendPayment(where: $where) {
      id
    }
  }
`;

export const DeleteSendMutation = gql`
  mutation deleteSend($where: SendWhereUniqueInput!) {
    deleteSend(where: $where) {
      id
    }
  }
`;
