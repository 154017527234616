import gql from "graphql-tag";
import { AccountPayload } from "./Account";
import { RequestPayload } from "./Request";
import { SendPayload } from "./Send";

export const BusinessPayload = gql`
  fragment BusinessPayload on User {
    id
    accounts {
      ...AccountPayload
    }
    address
    address2
    balance
    businessName
    businessType
    city
    contacts {
      id
      businessName
      customerType
      email
      firstName
      lastName
      profilePicture
    }
    customerStatus
    customerType
    dateOfBirth
    description
    email
    firstName
    froms {
      ...RequestPayload
    }
    invites {
      id
      for
      status
    }
    lastName
    members {
      id
      for
      status
      permissions
      user {
        id
        email
        firstName
        lastName
      }
    }
    pendingInvites {
      id
      status
      user {
        id
        email
      }
    }
    permissions {
      id
      permissions
      business {
        id
        customerType
        firstName
        lastName
        businessName
      }
    }
    plaidPublicToken
    postalCode
    profilePicture
    requests {
      ...RequestPayload
    }
    selectedAccount {
      ...AccountPayload
    }
    sends {
      ...SendPayload
    }
    tos {
      ...SendPayload
    }
    state
    timezone
    website
  }
  ${AccountPayload}
  ${RequestPayload}
  ${SendPayload}
`;

export const UserPayload = gql`
  fragment UserPayload on User {
    id
    accounts {
      ...AccountPayload
    }
    address
    address2
    balance
    business {
      ...BusinessPayload
    }
    businessName
    businessType
    city
    contacts {
      id
      businessName
      customerType
      email
      firstName
      lastName
      profilePicture
    }
    customerStatus
    customerType
    dateOfBirth
    description
    email
    firstName
    froms {
      ...RequestPayload
    }
    invites {
      id
      for
      status
    }
    lastName
    members {
      id
      for
      status
      permissions
      user {
        id
        email
        firstName
        lastName
      }
    }
    pendingInvites {
      id
      status
      user {
        id
        email
      }
    }
    permissions {
      id
      permissions
      business {
        id
        customerType
        firstName
        lastName
        businessName
      }
    }
    plaidPublicToken
    postalCode
    profilePicture
    requests {
      ...RequestPayload
    }
    selectedAccount {
      ...AccountPayload
    }
    sends {
      ...SendPayload
    }
    timezone
    tos {
      ...SendPayload
    }
    state
    token
    verificationData
    website
  }
  ${AccountPayload}
  ${RequestPayload}
  ${SendPayload}
  ${BusinessPayload}
`;

export const VerifyCustomerMutation = gql`
  mutation verifyCustomer($data: VerifyCustomerInput!) {
    verifyCustomer(data: $data) {
      ...UserPayload
    }
  }
  ${UserPayload}
`;

export const VerifyBeneficialOwnerMutation = gql`
  mutation verifyBeneficialOwner($data: VerifyBeneficialOwnerInput!) {
    verifyBeneficialOwner(data: $data) {
      ...UserPayload
    }
  }
  ${UserPayload}
`;

export const SignupMutation = gql`
  mutation signup($data: SignupInput!) {
    signup(data: $data) {
      token
      user {
        ...UserPayload
      }
    }
  }
  ${UserPayload}
`;

export const SignupAndPayMutation = gql`
  mutation signupAndPay($data: SignupAndPayInput!) {
    signupAndPay(data: $data) {
      token
      user {
        ...UserPayload
      }
    }
  }
  ${UserPayload}
`;

export const LoginMutation = gql`
  mutation login($data: LoginInput!) {
    login(data: $data) {
      token
      user {
        ...UserPayload
      }
    }
  }
  ${UserPayload}
`;

export const ResetPasswordMutation = gql`
  mutation resetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      success
    }
  }
`;

export const ResendEmailValidationMutation = gql`
  mutation resendEmailValidation($id: ID!) {
    resendEmailValidation(id: $id) {
      success
    }
  }
`;

export const UpdatePasswordMutation = gql`
  mutation updatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data) {
      token
      user {
        ...UserPayload
      }
    }
  }
  ${UserPayload}
`;

export const ValidateEmailMutation = gql`
  mutation validateEmail($data: ValidateEmailInput!) {
    validateEmail(data: $data) {
      token
      user {
        ...UserPayload
      }
    }
  }
  ${UserPayload}
`;

export const MeQuery = gql`
  query me {
    me {
      ...UserPayload
    }
  }
  ${UserPayload}
`;

export const BusinessQuery = gql`
  query business($token: String!) {
    business(token: $token) {
      ...UserPayload
    }
  }
  ${UserPayload}
`;

export const UpdateUserMutation = gql`
  mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      ...UserPayload
    }
  }
  ${UserPayload}
`;

export const LoginBusinessMutation = gql`
  mutation loginBusiness($data: LoginBusinessInput!) {
    loginBusiness(data: $data) {
      token
      user {
        ...UserPayload
      }
    }
  }
  ${UserPayload}
`;

export const LinkAccountMutation = gql`
  mutation linkAccount($data: LinkAccountInput!) {
    linkAccount(data: $data) {
      ...UserPayload
    }
  }
  ${UserPayload}
`;

export const InviteContactMutation = gql`
  mutation inviteContact($email: String!) {
    inviteContact(email: $email) {
      success
    }
  }
`;

export const InviteMemberMutation = gql`
  mutation inviteMember($data: InviteMemberInput!) {
    inviteMember(data: $data) {
      success
    }
  }
`;

export const ValidateTokenMutation = gql`
  mutation validateToken($token: String!) {
    validateToken(token: $token) {
      success
    }
  }
`;

export const CloseAccountMutation = gql`
  mutation closeAccount($id: ID!) {
    closeAccount(id: $id) {
      success
    }
  }
`;

export const AcceptInviteMutation = gql`
  mutation acceptInvite($id: ID!) {
    acceptInvite(id: $id) {
      success
    }
  }
`;

export const CheckBalanceQuery = gql`
  query checkBalance {
    checkBalance
  }
`;

export const AllUserDataQuery = gql`
  query allUserData {
    allUserData {
      address
      address2
      businessName
      businessType
      city
      createdAt
      customerType
      dateOfBirth
      description
      email
      firstName
      lastName
      postalCode
      state
      timezone
      website
      hasBankAccount
      hasTransacted
    }
  }
`;
